import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { contactUsAction, newsLetterAction } from './HomeActions';
import Loader2 from './Loader2';

const Footer = () => {
    const [lgShows, setLgShows] = useState(false);

    const [message, setMessage] = useState("")
    const [subject, setSubject] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [bookConsult, setBookConsult] = useState(1)
    

    // Contact us began here
    const dispatch= useDispatch()
    const [checkForm, setCheckForm] = useState(false)
    const contactUs = useSelector(state => state.contactUs)
    const {loading,error} = contactUs

    const submitHandler=()=>{
        if(!name || !email || !message || !subject){
            setCheckForm(true)
        }else{
            setCheckForm(false)
            setBookConsult(bookConsult + 1)
            dispatch(contactUsAction({
                name,email,
                subject,message
            }))
        }
    }

    const [newsEmail, setNewsEmail] = useState("")
    const newsLetter = useSelector(state => state.newsLetter)
    const {loading:loadings,error:errors,success} = newsLetter

    const submitNewsLetterHandler=()=>{
        if(!newsEmail){
            
        }else{
            dispatch(newsLetterAction({newsEmail}))
        }
    }
      
  return (
    <div className='zaFooter'>
        <Modal
            size="lg"
            show={lgShows}
            onHide={() => setLgShows(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <div className="flex">
                    <div>
                        <img src="https://img.icons8.com/bubbles/43/null/ringer-volume.png"/>
                    </div>
                    <div className='pl_1'>
                        <div className="pt_1 bold5">
                            Contact us
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="relative">
                    <div className={bookConsult === 1 ? "modalBodyy" : "d_none"}>
                        <div className="activities xrc">
                            <span className='active'></span>
                        </div>
                        <div className="mdalContent">
                            <div className="bold6 font_18">
                                We will love to hear from you
                            </div>
                            <div className="pt_1 DForm">
                                <input type="text" placeholder='Input Full Name'
                                    className={(checkForm && !name) ? "xdForm" : ""}
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                />
                                <input type="email" placeholder='Input Contact Email'
                                    className={(checkForm && !email) ? "xdForm" : ""}
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                                <input type="text" placeholder='Subject'
                                    className={(checkForm && !subject) ? "xdForm" : ""}
                                    value={subject}
                                    onChange={(e)=>setSubject(e.target.value)}
                                />
                                <textarea placeholder='Type here'
                                    className={(checkForm && !message) ? "xdForm" : ""}
                                    value={message}
                                    onChange={(e)=>setMessage(e.target.value)}
                                ></textarea>
                            </div>
                            
                        </div>
                    </div>
                    <div className={bookConsult === 2 ? "modalBodyy" : "d_none"}>
                        <div className="activities"></div>
                        <div className="mdalContent">
                            { loading ? 
                                <Loader />
                            :
                                <div>
                                    {error ? 
                                        <div>
                                            <div className="bold8 text-danger center">
                                                Unsuccessful
                                            </div>
                                            <div className="pt_2 font_14">
                                                There was an error with your request.
                                                Please try again later.
                                            </div>
                                        </div>
                                    :
                                        <div>
                                            <div className="bold8 text-success center">
                                                Your message was submitted successfully
                                            </div>
                                            <div className="pt_2 font_14">
                                                Your message was received. 
                                                You will get a response within 24hrs of 
                                                your request.
                                            </div>
                                            
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="mdalContent">
                        <div className={bookConsult === 1 ? "pb_3 flex" : "d_none"}>
                            <button className='NavRequestServicesBtn  ' onClick={()=>{
                                submitHandler()
                            }}>
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className=" px_2 flex xdc">
                        <div className='flex center2 xdccx'>
                            <div className='flex'>
                                <div>
                                    <img src="https://img.icons8.com/laces/40/null/secured-letter.png"/>
                                </div>
                                <div className='pt_05'>
                                    <a href="mailto:services@greyvalor.com" className='text-dark pl_1'>
                                        services@greyvalor.com
                                    </a>
                                </div>
                            </div>
                            <div className='flex pl_5'>
                                <div>
                                    <img src="https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/40/null/external-phone-customer-service-xnimrodx-lineal-xnimrodx-3.png"/>
                                </div>
                                <div className='pt_05'>
                                    <a href="tel:+2348160055931" className='text-dark pl_1'>
                                        +2348160055931
                                    </a>
                                </div>
                            </div>
                            <div className='pl_3'>
                                <Link to={"/home"} className="px_1">
                                    <img src="/Backgrounds/Instagram.svg" alt="." />
                                </Link>
                                <Link to={"/home"} className="px_1">
                                    <img src="/Backgrounds/facebook.svg" alt="." />
                                </Link>
                                <Link to={"/home"} className="px_1">
                                    <img src="/Backgrounds/twitter.svg" alt="." />
                                </Link>
                                <Link to={"/home"} className="px_1">
                                    <img src="/Backgrounds/youtube.svg" alt="." />
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Modal.Body>
        </Modal>
        <div className='relative'>
            <img src="/Backgrounds/curve.svg" alt="." className='backgroundFooterimg'/>
            <div className="magerFooter">
                <div className="thirdStandardWidth">
                    <div className="NewsLetter">
                        <div className='font_24 bold3 white'>
                            Subscribe Newsletter
                        </div>
                        <div className='NewsLetterFormContainer'>
                            <input type="text" placeholder='Enter your email'
                                value={newsEmail}
                                onChange={(e)=>setNewsEmail(e.target.value)}
                            />

                            <button onClick={submitNewsLetterHandler}
                                className={success ? "success" : loadings ? "loading" : "" }
                            >
                                {success ? "Success!" : loadings ? <Loader2 variant={"light"} /> : "Subscribe Now" }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='thirdStandardWidth'>
            <div className="footer">
                <div className='flexFooter'>
                    <Link to={"/about"} className='font_14 bold5 text-dark'>
                        About us
                    </Link>
                    <Link to={"/services"} className='font_14 bold5 text-dark'>
                        Services
                    </Link>
                    <Link to={"/faqs"} className='font_14 bold5 text-dark'>
                        FAQs
                    </Link>
                    <span className="font_14 bold5 text-dark pointer" 
                        onClick={() =>{
                            setBookConsult(1)
                            setLgShows(true)
                        }}
                    >
                        Contact Us
                    </span>
                </div>
                <div className='flex'>
                    <div className="left_auto">
                        <Link to={"/home"} className="px_1">
                            <img src="/Backgrounds/Instagram.svg" alt="." />
                        </Link>
                        <Link to={"/home"} className="px_1">
                            <img src="/Backgrounds/facebook.svg" alt="." />
                        </Link>
                        <a href='https://twitter.com/Grey_Valour?t=b055QSal47GUg-nqv4VxYA&s=08' target='_blank' className="px_1">
                            <img src="/Backgrounds/twitter.svg" alt="." />
                        </a>
                        <Link to={"/home"} className="px_1">
                            <img width="30" height="30" src="https://img.icons8.com/ios-filled/30/linkedin.png" alt="linkedin"/>
                        </Link>
                    </div>
                </div>
               
            </div>
            <div className="flex py_3">
                <div className="font_14 text-muted">
                    © 2023. All rights reserved.
                </div>
                {/* <div className="left_auto">
                    <span className="pr_3 font_14 text-muted">
                        Terms of Service    
                    </span>          
                    <span className='font_14 text-muted'>
                        Privacy Policy
                    </span>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default Footer