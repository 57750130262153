import React from 'react'

const ErrorPage = () => {
  return (
    <div className='alert alert-danger'>
        An error occured
    </div>
  )
}

export default ErrorPage