import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BookConsultation from './BookConsultation';
import Footer from './Footer';
import NavBar from './NavBar';

const MentorshipTutorship = () => {
    const [lgShow, setLgShow] = useState(true);
    const history = useNavigate()
    
    return (
        <div>
            <NavBar />
            <BookConsultation action={lgShow} />
            <div className="DigitalMArketingLander">
                <div className="thirdStandardWidth relative">
                    <div className='pt_5'>
                        <div className="red center bold6 font_12">
                            Nurturing Ambitions, One Connection at a Time
                        </div>
                        <div className="Header40 center white">
                            Guiding Your Success: Empowering Through Mentorship
                        </div>
                        <div className="pt_3 center white">
                            Grey Valour’s mentorship/ tutorship refers to the practice 
                            of providing one-on-one or small group instruction to clients 
                            in order to help them acquire knowledge, develop skills, and 
                            achieve career/business success. Tutors play a vital role in 
                            supplementing classroom learning, offering personalized 
                            guidance, and addressing individual learning needs.
                        </div>
                        <div className="pt_3 center">
                            <button className='GeneralBookingBtn'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                }}
                            >
                                Book Consultation
                            </button>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            <div className="secondStandardWidth">
                <div className="HomeAbout">
                    <div className="digMarktContainer">
                        <div>
                            <div className='font_38 bold7'>
                                Cultivating Your Potential:   
                            </div>
                            <div className="font_38 bold7 headerColor">
                                Guiding You Towards Growth
                            </div>
                            <div className="pt_1 text-muted font_14">
                                At Grey Valour, our tutorship can help you grow by expanding your 
                                knowledge, improving your communication and teaching skills, 
                                deepening your understanding of  learning, providing leadership 
                                and mentorship opportunities.
                            </div>
                        </div>
                        <div>
                            <div className='digitsContent'>
                                <div className='DigitList '>
                                    <div>
                                        <img src="https://img.icons8.com/nolan/64/clone-figure.png"/>
                                    </div>
                                    <div className="font_20 bold8">
                                        MENTORSHIP
                                    </div>
                                    <div className="pt_1 font_14">
                                        Welcome to our mentorship program, where we believe in the power of guidance and support to propel you towards success.
                                    </div>
                                    <div className="pt_1">
                                    <span className="bold7 font_14 text-primary pointer"
                                        onClick={() =>{
                                            setLgShow(!lgShow)
                                        }}
                                    >
                                        Contact Us
                                    </span>
                                    </div>
                                </div>
                                <div className='DigitList '>
                                    <div>
                                        <img src="https://img.icons8.com/nolan/64/clone-figure.png"/>
                                    </div>
                                    <div className="font_20 bold8">
                                        TUTORSHIP
                                    </div>
                                    <div className="pt_1 font_14">
                                        Welcome to our comprehensive tutorship program, designed to 
                                        provide you with the personalized support you 
                                        need to excel in your journey as a tech enthusiast.
                                    </div>
                                    <div className="pt_1">
                                    <span className="bold7 font_14 text-primary pointer"
                                        onClick={() =>{
                                            history("/tutorials")
                                        }}
                                    >
                                        View Active Tutorial
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer />
        </div>
    )
}

export default MentorshipTutorship