import React from 'react'

const ServicesDecode = ({KEYS}) => {
    const theList = {
        seo : "SEO/SEM",
        adc : "Ads campaign",
        cm : "Content marketing",
        smm : "Social media management",
        smn : "Social media network",
        em : "Email Marketing",
        cw : "Copy/Content writing",
        wd : "Web development",
        uid : "UI/UX Designs",
        gd : "Graphic Designs",
        ve : "Video editing",
        va : "Video ads creation",
        pm : "Project Management",
        product : "Product Design",
        cc : "Cloud Computing",
        dm : "Database Management",
        tutorship : "Tutorship/Mentorship",
        custom : "Make a Special Request"
    }
    return theList[KEYS]
}

export default ServicesDecode