import React, { useState } from 'react'
import DashboardNav from './DashboardNav'
import SideBar from './SideBar'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { settingsAction } from './HomeActions'
import Message from './Message'
import { SETTINGS_RESET } from './Constants'

const Settings = () => {
    const [formType,setFormType] = useState(false)
    
    const history = useNavigate()
    const dispatch = useDispatch()
    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin
    const settings = useSelector(state=> state.settings)
    const {error,userInfo:userinfo} = settings


    const [email,setEmail] = useState(userinfo ? userInfo.email : "")
    const [name,setName] = useState(userinfo ? userInfo.first_name : "")
    const [phone,setPhone] = useState(userinfo ? userInfo.last_name : "")
    const [password,setPassword] = useState("**********")

    const [errorMsg,setErrorMsg] = useState()
    const [errorPass,setErrorPass] = useState(false)

    useEffect(()=>{
        if (!userInfo){
            history(`/login?redirect=/settings`)
        }
        setPassword((error || userinfo) ? "**********" : "**********")
        setErrorMsg("")
        setErrorPass(false)
        dispatch({
            type: SETTINGS_RESET,
        })
    },[history,userInfo,error,userinfo])

    function password_validate(p) {
        return new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})").test(p)
    }
    const submitHandler = ()=>{
        setErrorMsg("")
        setErrorPass(false)
        dispatch({
            type: SETTINGS_RESET,
        })
        if(!password || !email || !phone || !name){
            setErrorMsg("Do not leave form field empty")
        }else if(password.length <5){
            setErrorMsg("Password field must be atleast 5 digits")
        }else if(password === "**********"){
            setErrorMsg("Invalid password field") 
        }else if(!password_validate(password)){
            setErrorPass(true) 
        }else{
            dispatch(settingsAction({email,password,phone,name}))
        }
    }
    return (
        <div>
            <div>
                <SideBar />
            </div>
            <div className='pt_1'></div>
            <div className='mainContent shadow'>
                <DashboardNav />
                <div className='mainContentDashboardsubs'>
                    <div className="DashboardTicketsContainer">
                        <div>
                            <div className="font_24 bold8">
                                Settings
                            </div>
                            {error && 
                                <div className='pt_2'>
                                    <Message variant={"danger"}>
                                        An error occured. Please try again latter.
                                    </Message>
                                </div>
                            }
                            {userinfo && 
                                <div className='pt_2'>
                                    <Message variant={"success"}>
                                        Profile update was successful
                                    </Message>
                                </div>
                            }
                            {errorMsg && 
                                <div className='pt_2'>
                                    <Message variant={"danger"} children={errorMsg}></Message>
                                </div>
                            }
                            {errorPass && 
                                <div className='pt_2'>
                                    <Message variant={"danger"}>
                                        <ul>
                                            <li className='font_12'>
                                                Password must contain at least 1 lowercase alphabetical character
                                            </li>
                                            <li className='font_12'>
                                                Password must contain at least 1 uppercase alphabetical character
                                            </li>
                                            <li className='font_12'>
                                                Password must contain at least 1 numeric character
                                            </li>
                                            <li className='font_12'>
                                                Password must contain at least one special character
                                            </li>
                                            <li className='font_12'>
                                                Password must be eight characters or longer
                                            </li>
                                        </ul>
                                    </Message>
                                </div>
                            }
                            <div className="profileUserContainer">
                                <div className="shadow innerUserProfile">
                                    <div className="divideProfileInfo">
                                        <div className="bold8">
                                            Name
                                        </div>
                                        <div>
                                            <input className="font_14 bold5" type="text" 
                                                value={name}
                                                onChange={(e)=>setName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="divideProfileInfo">
                                        <div className="bold8">
                                            Email
                                        </div>
                                        <div>
                                            <input className="font_14 bold5" type="email" disabled 
                                                value={email}
                                            />
                                        </div>
                                    </div>
                                    <div className="divideProfileInfo">
                                        <div className="bold8">
                                            Phone
                                        </div>
                                        <div>
                                            <input className="font_14 bold5" type="text"
                                                value={phone}
                                                onChange={(e)=>setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="divideProfileInfo">
                                        <div className="bold8">
                                            Password
                                        </div>
                                        <div className='divideSetiingsInfo'>
                                            <input className="font_14 bold5" type={!formType ? "password" : "text"}
                                                value={password}
                                                onChange={(e)=>setPassword(e.target.value)}
                                            />
                                            <div className='sight'>
                                                <img className={formType === false ? "pointer" : "d_none"} src="https://img.icons8.com/external-creatype-outline-colourcreatype/29/null/external-eye-essential-ui-v4-creatype-outline-colourcreatype.png"
                                                    onClick={()=>setFormType(!formType)}
                                                />
                                                <img className={formType === false ? "d_none" : "pointer"} src="https://img.icons8.com/external-creatype-glyph-colourcreatype/24/null/external-eyes-basic-creatype-glyph-colourcreatype-2.png"
                                                    onClick={()=>setFormType(!formType)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt_2">
                                <button className='SettingsSubmitBtn'
                                    onClick={submitHandler}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div></div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Settings