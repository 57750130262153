import React, { useState } from 'react'
import NavBar from './NavBar'
import Footer from './Footer'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import Message from './Message';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addStudentAction, paymentAction, tutorial_detailAction } from './HomeActions';
import { useEffect } from 'react';
import Loader from './Loader';
import DateFormater from './DateFormater';
import Loader2 from './Loader2';
import ErrorPage from './ErrorPage';
import { USER_LOGOUT } from './Constants';
import PaySymbol from './PaySymbol';
import { PaystackButton } from 'react-paystack';
import { Offline, Online } from "react-detect-offline";


const TutorialDetails = () => {
    const ids = useParams();
    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin
    const [show, setShow] = useState(false);
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [name,setName] = useState("")
    const [checkForm, setCheckForm] = useState(false)
    const [confirm_password,setConfirm_password] = useState("")
    const [formType2,setFormType2] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formType,setFormType] = useState(false)
    const [errorMsg,setErrorMsg] = useState()
    const [errorPass,setErrorPass] = useState(false)

    const history = useNavigate()
    const dispatch = useDispatch()
    const tutorialDetail = useSelector(state=> state.tutorialDetail)
    const {error, loading, tutorial, is_student,what_to_learn,is_not_aut} = tutorialDetail

    const addStudent = useSelector(state=> state.addStudent)
    const {error:errorSt, loading : loadin, message} = addStudent

    useEffect(()=>{
        if(ids.id){
            dispatch(tutorial_detailAction(ids.id))
        }
    },[ids])

    useEffect(()=>{
        if(is_not_aut === true && errorSt && loading === false ){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }
    },[is_not_aut])

    const componentProps = {
        email: userInfo ? userInfo.email : "",
        amount: (tutorial && tutorial.amount) ? tutorial.amount * 100 : 0,
        metadata: {
          name:userInfo ? userInfo.first_name : "",
        },
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
        text: tutorial ? `Pay now ${process.env.REACT_APP_CURRENCY_SYMBOL}${tutorial.amount}` : "Pay now",
        reference : (new Date()).getTime().toString(),
        onSuccess: (my_response) => dispatch(paymentAction({...my_response,amount:tutorial.amount,kind:"tutorial",currency : PaySymbol(process.env.REACT_APP_CURRENCY_SYMBOL),datas: tutorial ? tutorial : []})),
    }
    function password_validate(p) {
        return new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})").test(p)
    }

    const submitHandler = ()=>{
        setErrorMsg("")
        setErrorPass(false)
        if (!userInfo){
            if(!name || !email || !password || !confirm_password){
                setCheckForm(true)
            }else if(password !== confirm_password){
                setCheckForm(false)
                setErrorMsg("Password field did not match")
            }else if(password.length <5){
                setCheckForm(false)
                setErrorMsg("Password must be more than 5 characters long")
            }else if(!password_validate(password)){
                setCheckForm(false)
                setErrorPass(true) 
            }else{
                setCheckForm(false)
                setErrorMsg("")
                setErrorPass(false)
                dispatch(addStudentAction({
                    "password": password,
                    "email": email,
                    "name" : name,
                    "id": tutorial.id
                }))
            }
        }else if(userInfo){
            setCheckForm(false)
            setErrorMsg("")
            setErrorPass(false)
            dispatch(addStudentAction({
                "id": tutorial.id
            }))
        }
    }
    
    return (
        <div>
            <NavBar /> 
            <Modal show={show} onHide={handleClose} className='xmxs'>
                <Modal.Header closeButton>
                    <div className="flex">
                        <div>
                            <img width="50" height="50" src="https://img.icons8.com/bubbles/43/guest-male.png" alt="guest-male"/>
                        </div>
                        {userInfo ? 
                            <div className='pl_1'>
                                <div className="pt_1 bold5">
                                    Application
                                </div>
                            </div>
                        :   
                            <div className='pl_1'>
                                <div className="pt_1 bold5">
                                    Account set up
                                </div>
                            </div>
                        }
                        
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="relative">
                        <div className="modalBodyy">
                            <div className="activities xrc">
                                <span className='active'></span>
                            </div>
                            {userInfo ? 
                                <div className='mdalContent'>
                                    <Offline>
                                        <Message variant={"danger"} children={`You are offline. Please check your internet connection.`}></Message>
                                    </Offline>
                                    {message &&
                                        <div className="bold6 font_18 text-success">
                                            Application was successful.
                                        </div>
                                    }
                                    {errorSt && <Message variant={"danger"} children={`User with that email already exist.`}></Message>}
                                    {message && <Message variant={"warning"} children={message}></Message>}
                                    {message===null && <Message variant={"danger"} children={"Invalide form"}></Message>}

                                    {message &&
                                        <div className="pt_1 DForm center">
                                            <img src="/Images/check-mark-verified.gif" alt="Image" />
                                        </div>
                                    }
                                    <div className="bold7">
                                        {tutorial && tutorial.title}
                                    </div>
                                    <div>
                                        Classes begins on :
                                        {/* This should be date for the every day class */}
                                        <span className="pl_1 font_12">
                                            {tutorial && <DateFormater date={tutorial.start_date} />}
                                        </span>
                                    </div>
                                </div>
                           :
                                <div className="mdalContent">
                                    <Offline>
                                        <Message variant={"danger"} children={`You are offline. Please check your internet connection.`}></Message>
                                    </Offline>
                                    {message ?
                                        <div className="bold6 font_18 text-success">
                                            You are almost ready.
                                        </div>
                                        :
                                        <div className="bold6 font_18">
                                            Lets set up your account first.
                                        </div>
                                    }
                                    {errorMsg && 
                                        <Message variant={"danger"} children={errorMsg}></Message>
                                    }
                                    {error && <Message variant={"danger"} children={`User with that email(${email}) already exist.`}></Message>}
                                    {errorSt && <Message variant={"danger"} children={`User with that email(${email}) already exist.`}></Message>}
                                    {message && <Message variant={"warning"} children={message}></Message>}
                                    {message===null && <Message variant={"danger"} children={"Invalide form"}></Message>}
                                    {errorPass &&
                                        <div>
                                            <Message variant={"danger"}>
                                                <ul>
                                                    <li className='font_11'>
                                                        Password must contain at least 1 lowercase alphabetical character
                                                    </li>
                                                    <li className='font_11'>
                                                        Password must contain at least 1 uppercase alphabetical character
                                                    </li>
                                                    <li className='font_11'>
                                                        Password must contain at least 1 numeric character
                                                    </li>
                                                    <li className='font_11'>
                                                        Password must contain at least one special character
                                                    </li>
                                                    <li className='font_11'>
                                                        Password must be eight characters or longer
                                                    </li>
                                                </ul>
                                            </Message>
                                        </div>
                                    }

                                    {(message && message !== "That email is already taken") ?
                                        <div className="pt_1 DForm center">
                                            <img src="/Images/check-mark-verified.gif" alt=".." />
                                        </div>
                                    :
                                        <div className="pt_1 DForm">
                                            <input type="text" placeholder='Input Full Name'
                                                className={(checkForm && !name) ? "xdForm" : ""}
                                                value={name}
                                                onChange={(e)=>setName(e.target.value)}
                                            />
                                            <input type="email" placeholder='Input Contact Email'
                                                className={(checkForm && !email) ? "xdForm" : ""}
                                                value={email}
                                                onChange={(e)=>setEmail(e.target.value)}
                                            />
                                            <div className={(checkForm && !password) ? "xdForm passwordFormContainer" : "passwordFormContainer"}>
                                                <input className='xb' type={formType === false ? "password" : "text"} placeholder='Password' 
                                                    value={password}
                                                    onChange={(e)=>setPassword(e.target.value)}
                                                />
                                                <div className='sight'>
                                                    <img className={formType === false ? "pointer" : "d_none"} src="https://img.icons8.com/external-creatype-outline-colourcreatype/29/null/external-eye-essential-ui-v4-creatype-outline-colourcreatype.png"
                                                        onClick={()=>setFormType(!formType)}
                                                    />
                                                    <img className={formType === false ? "d_none" : "pointer"} src="https://img.icons8.com/external-creatype-glyph-colourcreatype/24/null/external-eyes-basic-creatype-glyph-colourcreatype-2.png"
                                                        onClick={()=>setFormType(!formType)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={(checkForm && !confirm_password) ? "xdForm passwordFormContainer" : "passwordFormContainer"}>
                                                <input className='xb' type={formType2 === false ? "password" : "text"} placeholder='Password' 
                                                    value={confirm_password}
                                                    onChange={(e)=>setConfirm_password(e.target.value)}
                                                />
                                                <div className='sight'>
                                                    <img className={formType2 === false ? "pointer" : "d_none"} src="https://img.icons8.com/external-creatype-outline-colourcreatype/29/null/external-eye-essential-ui-v4-creatype-outline-colourcreatype.png"
                                                        onClick={()=>setFormType2(!formType2)}
                                                    />
                                                    <img className={formType2 === false ? "d_none" : "pointer"} src="https://img.icons8.com/external-creatype-glyph-colourcreatype/24/null/external-eyes-basic-creatype-glyph-colourcreatype-2.png"
                                                        onClick={()=>setFormType2(!formType2)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            }
                        </div>
                        <div className="mdalContent">
                            {userInfo ?
                                <div className="pb_3">
                                    {loadin ? 
                                        <button className='NavRequestServicesBtn'>
                                            <Loader2 />
                                        </button>
                                    :
                                        <div>
                                            {!message &&
                                                <div>
                                                    {(!is_student && tutorial && tutorial.amount) ?
                                                        <PaystackButton className="paystack-button left_auto btn-sm btn btn-secondary bg-secondary" {...componentProps} />
                                                    :
                                                        <button className='NavRequestServicesBtn' onClick={()=>submitHandler()}>
                                                            Apply
                                                        </button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                    
                                </div>
                            :
                                <div className="pb_3">
                                    {loadin ? 
                                        <button className='NavRequestServicesBtn'>
                                            <Loader2 />
                                        </button>
                                    :
                                        <button className='NavRequestServicesBtn' onClick={()=>{
                                            message ? history(`/login?redirect=/tutorial-detail/${ids.id}`) : submitHandler()
                                        }}>
                                            {message ? "Login" : "Submit"}
                                        </button>
                                    }
                                    {!message && 
                                        <div className='font_14 pt_1'>
                                            You already have an account?
                                            <Link to={`/login?redirect=/tutorial-detail/${ids.id}`} className='pl_1'>
                                                login...
                                            </Link>
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className=" bg-dark">
                <div className="firstStandardWidth">
                    {error && <ErrorPage />}
                    {loading && <div className='p-3'><Loader /></div>}
                    {tutorial && 
                        <div className='tutDtal'>
                            <div className='p_2'>
                                <div className="Header3 text-white">
                                    {tutorial.title}
                                </div>
                                <div className='text-white py-3'>
                                    {tutorial.description}
                                </div>
                                <div className='pb_1'>
                                    <span className="text-warning bold6 font_12">
                                        Tutorial Start Date :
                                    </span>
                                    <span className="pl_1 text-white font_12">
                                        <DateFormater date={tutorial.start_date} />
                                    </span>
                                </div>
                                {(userInfo && is_student) ? 
                                    <div>
                                        {tutorial.is_done ?
                                            <Link className='font_18 bold6' to={`/certificate/${tutorial.course_id}${userInfo.id}`}>
                                                View Certificate
                                            </Link>
                                        :
                                            <div>
                                                <div className='font_14 bold6 text-white'>
                                                    Join with link : 
                                                </div>
                                                <a href={tutorial.link} target='_blank'>
                                                    {tutorial.link}
                                                </a>
                                            </div>
                                        }
                                    </div>
                                :
                                    <button className='enrolTutorialBtn'
                                        onClick={handleShow}
                                    >
                                        Join for {tutorial.amount ? `${process.env.REACT_APP_CURRENCY_SYMBOL} ${(tutorial.amount).toLocaleString("en-US")}` : "free"}
                                    </button>
                                }
                            </div>
                            <div className='checkOrderly'>
                                {/* <img src={`${tutorial.image}`} alt="." className='round' /> */}
                                <img src="/Images/web dv 2.jpg" alt="." className='round' />
                            </div>
                        </div>
                    }
                </div>
            </div>
            {what_to_learn && 
                <div className="classCpontent firstStandardWidth">
                    <div className="mainClasscontent">
                        <div className="font_16 bold7 pb_2">
                            What you'll learn from this course: 
                        </div>
                        <div className='theDividersGrid'>
                            {what_to_learn.map((x,i)=>(
                                <ul key={i + 1}>
                                    <li>
                                        {x}
                                    </li>
                                </ul>
                            ))}
                            
                        </div>
                    </div>
                    <div className='classDuration'>
                        <div className="bold6">
                            This course features :
                        </div>
                        <div className="py-3">
                            <div className="bold6 font_14">
                                Start Date -- End Date:
                            </div>
                            <div className='flex py_1'>
                                <span className="text-muted">
                                    <DateFormater date={tutorial.start_date} />
                                </span>
                                <span className='px_1'>
                                    -- 
                                </span>
                                <span className="text-muted">
                                    <DateFormater date={tutorial.end_date} />
                                </span>
                            </div>
                            {tutorial.is_cert_awarded && 
                                <div className='font_16 bold6 text-success'>
                                    Certificate Awarded
                                </div>
                            }
                            <div className="pt_2">
                                {(userInfo && is_student) ? 
                                    <div>
                                        {tutorial.is_done ?
                                            <Link className='font_18 bold6' to={`/certificate/${tutorial.course_id}${userInfo.id}`}>
                                                View Certificate
                                            </Link>
                                        :
                                            <div>
                                                <div className='font_14 bold6 text-white'>
                                                    Join with link : 
                                                </div>
                                                <a href={tutorial.link} target='_blank'>
                                                    {tutorial.link}
                                                </a>
                                            </div>
                                        }
                                    </div>
                                :
                                    <button className='enrolTutorialBtn'
                                        onClick={handleShow}
                                    >
                                        Join for {tutorial.amount ? `${process.env.REACT_APP_CURRENCY_SYMBOL} ${(tutorial.amount).toLocaleString("en-US")}` : "free"}
                                    </button>
                                }
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}

export default TutorialDetails