import React, { useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { certAction } from './HomeActions';
import Message from './Message';
import DateFormater from './DateFormater';
import { useState } from 'react';
import Logo from './Logo';

const Certificate = () => {
    const ids = useParams()
    const dispatch = useDispatch()
    const certificate = useSelector(state=> state.certificate)
    // const {error, loading, tutorial,what_to_learn,course_id,is_student,namer} = certificate
    const {error, loading, tutorial,course_id,is_student,namer} = certificate
    
    const pdfRef = useRef()
    const downloadPdf = () =>{
        const input = pdfRef.current;
        html2canvas(input).then((canvas)=>{
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF('p', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 30;
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio)
            pdf.save('GrevalourCertifate.pdf')

        })
    }
    
    const [showMobileWarning, setShowMobileWarning] = useState(true)

    useEffect(() => {
        if(window.innerWidth <= 800){
            setShowMobileWarning(false)
        }
    }, [])
    useEffect(()=>{
        if(ids.id){
            dispatch(certAction(ids.id))
        }
    },[ids])

    
    return (
        <div>
            {error && <Message variant={"danger"}>Something went wrong, please try again</Message>}
            {showMobileWarning &&
                <div className='certificate_bg'>
                    <div className='sticky-top pb_1'>
                        <button className='NavRequestServicesBtn' onClick={downloadPdf}>
                            Download pdf
                        </button>
                    </div>

                    
                    {(tutorial && is_student === true) ? 
                        <div className="certificate" ref={pdfRef}>
                            <Logo />
                            <div className="pt_3 bold6">
                                CERTIFCATE OF COMPLETION
                            </div>
                            <div className="bold7 font_32 pt_1">
                                    {tutorial.title}
                            </div>
                            <div className='pt_2'>
                                <span>Instructor(s) : </span>
                                <span className="pl_1 bold6 font_18">
                                    {tutorial.instructor}
                                </span>
                            </div>
                            <div className="pt_9">
                                <span className="bold6">Date : </span>
                                <span><DateFormater date={tutorial.end_date} /></span>
                                <div className="py_2 bold7 font_22">
                                    {namer}
                                </div>
                                <div>
                                    Certificate Number: {course_id}
                                </div>
                                <div>
                                    Verify: {process.env.REACT_APP_BASE_URL}/certificate/{course_id}
                                </div>
                            </div>
                        </div>
                    :
                        <div>
                            {loading === false && is_student === false &&
                                <div className='certificate rounded-lg'>
                                    <Message variant={"danger"} children={"Invalide Certificate"} />
                                </div>
                            }
                        </div>
                    }
                
                </div>
            }
            {!showMobileWarning && 
                <div className='certificate_bg'>
                    <div className='certificate rounded-lg'>
                        <div className='logoSize pb_2'>
                            <Link to={"/"}>
                                <img src="/Logo/GV.png" alt="." />
                            </Link>
                        </div>
                        <Message variant={"warning"} children={"This page is only available on desktop view"} />
                    </div>
                </div>
            }
        </div>
    );
};
  
export default Certificate
  
  