import React, { useState } from 'react'
import Footer from './Footer'
import NavBar from './NavBar'
import BookConsultation from './BookConsultation'
import { Link } from 'react-router-dom'

const Designs = () => {
    const [servece, setServece] = useState("");
    const [lgShow, setLgShow] = useState(true);

  return (
    <div>
        <NavBar />
        <BookConsultation action={lgShow} service={servece} />
        
        <div className='relative'>
            <div className="thirdStandardWidth relative">
                <div className='pt_5'>
                    <div className="red center bold6 font_12">
                        Design
                    </div>
                    <div className="Header40 center">
                        GET MORE OUT OF DESIGNS FROM GREYVALOR
                    </div>
                    <div className="pt_3 center">
                        Grey Valour’s concept of design refers to the process of creating 
                        or formulating a plan, idea, or blueprint for the development or 
                        creation of a product, system, structure, or artwork. 
                        Grey Valour’s Design encompasses various disciplines and fields,
                         including graphic design, video editing and Product Design
                    </div>
                    <div className="pt_3 center">
                        <button className='GeneralBookingBtn'
                            onClick={() => {
                                setLgShow(!lgShow)
                            }}
                        >
                            Request Services
                        </button>
                    </div>
                </div>
                
            </div>
        </div>
        <div className="secondStandardWidth">
            <img src="/Images/design.png" alt="." />
        </div>
        <div className="bg_Services relative">

        
            <div className="thirdStandardWidth ">
                <div className="HomeAbout deigns">
                    <div className="red center bold6 font_12">
                    Designs
                    </div>
                    <div className="Header2 center">
                        Our Services
                    </div>
                    <div className="pt_3 center">
                        Grey Valour offers a spectrum of services to promote excellence and growth in your company, business or establishment by ensuring it has an engaging digital presence with the platform to share innovative ideas with business owners via our online community.
                    </div>
                    <div className="borderBottoms"></div>
                    
                </div>
                <img src="/Backgrounds/rect.svg" alt="." className='square9' />
                <img src="/Backgrounds/startLight.svg" alt="." className='square10' />
                <img src="/Backgrounds/square5.svg" alt="." className='square11' />
                <img src="/Backgrounds/square6.svg" alt="." className='square12' />
            </div>
            <div className="secondStandardWidth">
                <div className="ServicesContainers px_5">
                    <div className="colsM">
                        <div className='center'>

                            <img src="/Images/desgner.png" alt="" />
                        </div>
                        <div className=" center bold6 font_18">
                            Graphic Design
                        </div>
                        <div className="font_13 pt_1 center">
                            Grey Valour’s  concept of graphic design involves the creation 
                            and arrangement of visual elements to convey a message, 
                            communicate information, or evoke a certain response.
                        </div>
                        <div className="pt_1 center">
                            <button className='bookserViceOption bg-none'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("gd")
                                }}
                            >
                                Book Service
                            </button>
                        </div>
                    </div>
                    <div className="colsM">
                        <div className='center'>
                            <div className="divImg">
                                <img src="/Images/designerTesr.png" alt="" />
                            </div>
                        </div>
                        <div className=" center bold6 font_18">
                            Video Editing
                        </div>
                        <div className="font_13 pt_1 center">
                            At Grey Valour, our video editing concept  refers to the process 
                            of manipulating and arranging video footage, audio, and 
                            other elements to create a cohesive and visually compelling video.
                        </div>
                        <div className="pt_1 center">
                            <button className='bookserViceOption bg-none'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("ve")
                                }}
                            >
                                Book Service
                            </button>
                        </div>
                    </div>
                    <div className="colsM">
                        <div className='center'>
                            <img src="/Images/Ydesign.png" alt="" />
                        </div>
                        <div className=" center bold6 font_18">
                            Product Design
                        </div>
                        <div className="font_13 pt_1 center">
                            Grey Valour's product design involves creating, improving, and 
                            manufacturing products with a multidisciplinary approach, 
                            considering aesthetics, functionality, usability, and the market.
                        </div>
                        <div className="pt_1 center">
                            <button className='bookserViceOption bg-none'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("product")
                                }}
                            >
                                Book Service
                            </button>
                        </div>
                    </div>
                    {/* <div className="colsM">
                        <div className='center divImg'>
                            <img src="/Images/perfecto.png" alt="." />
                        </div>
                        <div className="center bold6 font_18">
                            Graphic Design
                        </div>
                        <div className="pt_1 font_13 center">
                            consectetur adipiscing elit. Nulla est Lorem ipsum dolor sit amet
                        </div>
                        <div className="pt_1 center">
                            <button className='bookserViceOption bg-none'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("gd")
                                }}
                            >
                                Book Service
                            </button>
                        </div>
                    </div> */}
                </div>
                
            </div>
            <div className="HomeAbout xdr"></div>
        </div>
        <Footer />
    </div>
  )
}

export default Designs