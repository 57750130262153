import React, { useState } from 'react';
// import { Link } from 'react-router-dom'
import Footer from './Footer'
import NavBar from './NavBar'
import BookConsultation from './BookConsultation';

const Service = () => {
    const [lgShow, setLgShow] = useState(true);
    const [servece, setServece] = useState("");
  return (
    <div className='serve'>
        <NavBar />
        <BookConsultation action={lgShow} service={servece} />
        <div className="LandingPage">
            <div className="relative xf">
                <div className="secondStandardWidth h_100">
                    <div className="centerMeServices">
                        <div className="centerServices">
                            <div className="HeaderOne center">
                                Explore Our Services
                            </div>
                            <div className="pt_1 center">
                                <div className="pr_2">
                                    Are you looking for a one-stop where you can find all digital services? 
                                    Kindly explore our wide range of services that will showcase your 
                                    brand positively. 
                                </div>
                            </div>
                            <div className="pt_2 center">
                                <button className='GeneralBookingBtn'
                                    onClick={() => {
                                        setLgShow(!lgShow)
                                    }}
                                >
                                    Book Consultation
                                </button>
                            </div>
                            
                        </div>
                        <div className="border-top mt_9">
                            <div className="pt_1 font_20 bold7 headerColor">
                                Quick Service
                            </div>
                            <div className="idli" id="xd">
                                <div className="QucikServices">
                                    <div className="shadow gridDisp pointer"
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("wd")
                                        }}
                                    >
                                        <div>
                                            <img src="https://img.icons8.com/bubbles/50/null/web.png"/>
                                        </div>
                                        <div className="font_14 bold6 pt_15 ml_1">
                                            Web Design
                                        </div>
                                    </div>
                                    <div className="shadow gridDisp pointer"
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("ma")
                                        }}
                                    >
                                        <div>
                                            <img src="https://img.icons8.com/bubbles/50/null/iphone.png"/>
                                        </div>
                                        <div className="font_14 bold6 pt_15 ml_1">
                                            Mobile Design
                                        </div>
                                    </div>
                                    <div className="shadow gridDisp pointer"
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("uid")
                                        }}
                                    >
                                        <div>
                                            <img src="https://img.icons8.com/bubbles/50/null/figma.png"/>
                                        </div>
                                        <div className="font_14 bold6 pt_15 ml_1">
                                            UI/UX Design
                                        </div>
                                    </div>
                                    <div className="shadow gridDisp pointer"
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("ve")
                                        }}
                                    >
                                        <div>
                                            <img src="https://img.icons8.com/bubbles/50/null/clapperboard.png"/>
                                        </div>
                                        <div className="font_14 bold6 pt_15 ml_1">
                                            Video Editing
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="/Backgrounds/sqare1.svg" alt="." className='square1' />
                <img src="/Backgrounds/square2.svg" alt="." className='square2' />
                <img src="/Backgrounds/square3.svg" alt="." className='square3' />
            </div>
            <div className="centerMeServices relative">
                <div className="flex">
                    <div className='pr_3'>
                        <img src="/Images/Hero Image.png" alt="GreyValor" />
                    </div>
                    <div className="pr_4">
                        <div className="bold6 font_28 pt_9">
                            Here are the list of services we offer:
                        </div>
                        <div className="pt_2">
                            <div className='pb_1'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("seo")
                                }}
                            >
                                <img src="https://img.icons8.com/bubbles/40/null/rating-circled.png"/>
                                <span className="pl_1 font_16 bold6 pointer">
                                    SEO, SEM
                                </span>
                            </div>
                            <div className='pb_1'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("wd")
                                }}
                            >
                                <img src="https://img.icons8.com/bubbles/40/null/rating-circled.png"/>
                                <span className="pl_1 font_16 bold6 pointer">
                                    Web development
                                </span>
                            </div>
                            <div className='pb_1'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("ma")
                                }}
                            >
                                <img src="https://img.icons8.com/bubbles/40/null/rating-circled.png"/>
                                <span className="pl_1 font_16 bold6 pointer">
                                    Mobile app development
                                </span>
                            </div>
                            <div className='pb_1'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("uid")
                                }}
                            >
                                <img src="https://img.icons8.com/bubbles/40/null/rating-circled.png"/>
                                <span className="pl_1 font_16 bold6 pointer">
                                    UI/UX Designs
                                </span>
                            </div>
                            <div className='pb_1'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("gd")
                                }}
                            >
                                <img src="https://img.icons8.com/bubbles/40/null/rating-circled.png"/>
                                <span className="pl_1 font_16 bold6 pointer">
                                    Graphic Designs
                                </span>
                            </div>
                            <div className='pb_1'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("ve")
                                }}
                            >
                                <img src="https://img.icons8.com/bubbles/40/null/rating-circled.png"/>
                                <span className="pl_1 font_16 bold6 pointer">
                                    Video editing
                                </span>
                            </div>
                            <div className='pb_1'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("va")
                                }}
                            >
                                <img src="https://img.icons8.com/bubbles/40/null/rating-circled.png"/>
                                <span className="pl_1 font_16 bold6 pointer">
                                    Video ads creation
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg_Brownish'>
                    <div className="secondStandardWidth">
                        <div className="pt_4">
                            <div className="bold7 font_24">
                                More services
                            </div>
                            <div className="pt_2 displayMoreServices">
                                <div>
                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("adc")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Ads campaign
                                        </span>
                                    </div>
                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("cm")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Content marketing
                                        </span>
                                    </div>
                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("smm")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Social media management
                                        </span>
                                    </div>
                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("smn")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Social media network
                                        </span>
                                    </div>

                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("em")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Email Marketing
                                        </span>
                                    </div>
                                    
                                    
                                </div>
                                <div>
                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("cw")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Copy/Content writing
                                        </span>
                                    </div>
                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("pm")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Project Management
                                        </span>
                                    </div>
                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("pd")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Product Design
                                        </span>
                                    </div>
                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("cc")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Cloud Computing
                                        </span>
                                    </div>

                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("dm")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Database Management
                                        </span>
                                    </div>
                                    
                                    
                                </div>
                                <div>
                                    <div className='pb_1'
                                        onClick={() => {
                                            setLgShow(!lgShow)
                                            setServece("tutorship")
                                        }}
                                    >
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6 pointer">
                                            Tutorship/Mentorship
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt_2 bold7 text-primary pointer"
                            onClick={() => {
                                setLgShow(!lgShow)
                            }}
                        >
                            Make a custom request ...
                        </div>
                    </div>
                </div>
                <img src="/Backgrounds/rect.svg" alt="." className='square9' />
                <img src="/Backgrounds/startLight.svg" alt="." className='square10' />
                <img src="/Backgrounds/square5.svg" alt="." className='square11' />
                <img src="/Backgrounds/square6.svg" alt="." className='square12' />

            </div>
        </div>
        <Footer />

    </div>
  )
}

export default Service