import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BookConsultation from './BookConsultation';
import Footer from './Footer';
import NavBar from './NavBar';

const DigitalMarketing = () => {
    const [servece, setServece] = useState("");
    const [lgShow, setLgShow] = useState(true);
  return (
    <div>
        <NavBar />
        <BookConsultation action={lgShow} service={servece} />
        <div className='DigitalMarketing'>
            <div className="DigitalMarketingContainer">
                <div>
                    <div className="font_38 bold7">
                        DIGITAL MARKETING
                    </div>
                    <div className="pt_2 xlx">
                        Grey Valour’s concept of digital marketing refers to the practice of promoting products, services, or brands using digital channels and technologies. 
                    </div>
                    <div className="pt_2">
                        <button className='GeneralBookingBtn'
                            onClick={() => {
                                setLgShow(!lgShow)
                            }}
                        >
                            Request Services
                        </button>
                    </div>
                </div>
                <div>
                    <img src="/Images/digitalMarket.svg" alt="" />
                </div>
            </div>
        </div>
        <div className="secondStandardWidth">
            <div className="HomeAbout deigns">
                <div className="digMarktContents">
                    <div>
                        <div className="blue font_12 bold7">
                            Digital marketing
                        </div>
                        <div className="pt_1 font_32 bold7">
                            Social Media Management
                        </div>
                        <div className="text-muted">
                            Grey Valour’s concept of social media management refers to 
                            the process of creating, scheduling, analyzing, and 
                            engaging with content on social media platforms to build 
                            and maintain an organization's online presence.
                        </div>
                    </div>
                    <div>
                        <div className="shadow shads">
                            <div className="font_14 ">
                                Our technique involves managing social media accounts, crafting 
                                and publishing content, monitoring engagement, and implementing 
                                strategies to achieve specific marketing or communication goals
                            </div>
                            
                            <button className='digitsBookBtn'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("smm")
                                }}
                            >
                                Request
                            </button>
                            <div className="shadowMe shadow"></div>
                        </div>
                    </div>

                </div>
                <div className="digMarktContents">
                    <div>
                        <div className="blue font_12 bold7">
                            Digital marketing
                        </div>
                        <div className="pt_1 font_32 bold7">
                            SEO
                        </div>
                        <div className="text-muted">
                            Grey Valour’s concept of SEO, short for search engine 
                            optimization, is the practice of improving a website's 
                            visibility and ranking in search engine results pages (SERPs). 
                        </div>
                    </div>
                    <div>
                        <div className="shadow shads">
                            <div className="font_14 ">
                                Our technique involves optimizing various elements on a 
                                website to make it more relevant, authoritative, and 
                                user-friendly, thereby increasing organic (non-paid) 
                                traffic from search engines.
                            </div>
                            <button className='digitsBookBtn'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("seo")
                                }}
                            >
                                Request
                            </button>
                            <div className="shadowMe shadow"></div>
                        </div>
                    </div>

                </div>
                <div className="digMarktContents">
                    <div>
                        <div className="blue font_12 bold7">
                            Digital marketing
                        </div>
                        <div className="pt_1 font_32 bold7">
                            Digital ADs Campaign
                        </div>
                        <div className="text-muted">
                            Grey Valour’s concept of a digital advertising campaign refers to a strategic and coordinated series of advertisements and promotional activities that are executed across various digital channels to achieve specific marketing objectives. 
                        </div>
                    </div>
                    <div>
                        <div className="shadow shads">
                            <div className="font_14 ">
                                Our digital ads campaigns leverage online platforms and 
                                technologies to reach and engage target audiences, drive 
                                conversions, and generate business results.
                            </div>
                            <button className='digitsBookBtn'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("adc")
                                }}
                            >
                                Request
                            </button>
                            <div className="shadowMe shadow"></div>
                        </div>
                    </div>

                </div>
                <div className="digMarktContents">
                    <div>
                        <div className="blue font_12 bold7">
                            Digital marketing
                        </div>
                        <div className="pt_1 font_32 bold7">
                            Content Marketing Materials
                        </div>
                        <div className="text-muted">
                            At Grey Valour, our content marketing materials refer to 
                            the various types of content created and used in a content 
                            marketing strategy to attract, engage, and convert target 
                            audiences. 
                        </div>
                    </div>
                    <div>
                        <div className="shadow shads">
                            <div className="font_14 ">
                                Our content marketing  materials are designed to provide value, 
                                educate, entertain, and build relationships with the audience, 
                                ultimately driving them to take desired actions. Here are some 
                                of our  content marketing materials: Infographics, videos, 
                                social media posts, email newsletters, webinars and  online 
                                courses.
                            </div>
                            <button className='digitsBookBtn'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                    setServece("cm")
                                }}
                            >
                                Request
                            </button>
                            <div className="shadowMe shadow"></div>
                        </div>
                    </div>

                </div>
            </div>
            
        </div>
        <Footer />
    </div>
  )
}

export default DigitalMarketing