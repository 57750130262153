import React, { useEffect, useState } from 'react'
import DashboardNav from './DashboardNav'
import SideBar from './SideBar'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { dashboardAction, handleProjectAction } from './HomeActions'
import Loader from './Loader'
import IsEmpty from './IsEmpty'
import Modal from 'react-bootstrap/Modal';
import ServicesDecode from './ServicesDecode'
import { DELETE_PROJECT_RESET } from './Constants'
import DateFormater from './DateFormater'
import Lengther from './Lengther'

const MadeRequests = () => {
    const [smShow, setSmShow] = useState(false);
    const [delVal, setDelVal] = useState(-1);
    
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useNavigate()

    const redirect = location.search ? location.search.split("=")[1]: "/login"

    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin

    const handleProjectDel = useSelector(state=> state.handleProjectDel)
    const {error:errorDel, loading:loadingDel, success} = handleProjectDel

    const dashboard = useSelector(state => state.dashboard)
    const {error,loading,projects} =dashboard

    useEffect(()=>{
        if (!userInfo){
            history(`/login?redirect=/made-requests`)
        }
        dispatch(dashboardAction())
    },[history,userInfo,redirect,dispatch,localStores,success])

    const [localStores, setLocalStores] = useState(userInfo ? (localStorage.getItem(`Cart${userInfo.id}`) ? JSON.parse(localStorage.getItem(`Cart${userInfo.id}`)) : []) : []);
    // const [localStores, setLocalStores] = useState(userInfo ? (projects ? projects : []) : []);
    
    useEffect(()=>{
        if(projects && !IsEmpty(projects)){
            if (!IsEmpty(localStores)){
                let idsz = []
                localStores.map(x => idsz.unshift(x.id))
                let newStoresAvil = projects.filter(x=> idsz.includes(x.id))
                localStorage.setItem(`Cart${userInfo.id}`, JSON.stringify(newStoresAvil))
                setLocalStores(newStoresAvil)
            }
        }else if( userInfo && projects && IsEmpty(projects)){
            localStorage.setItem(`Cart${userInfo.id}`, JSON.stringify([]))
            setLocalStores([])
        }
    },[projects,history,userInfo,redirect,dispatch])
    const HandlePaymentSelection = (x)=>{
        let cart = localStorage.getItem(`Cart${userInfo.id}`) ? JSON.parse(localStorage.getItem(`Cart${userInfo.id}`)) : []

        let cartfilt = cart.filter(m =>m.id === x.id)
        
        if (IsEmpty(cart)){
            localStorage.setItem(`Cart${userInfo.id}`, JSON.stringify([x]))
            setLocalStores([x])
        }else if(!IsEmpty(cartfilt)){
            let cartfilter = cart.filter(m =>m.id !== x.id)
            localStorage.setItem(`Cart${userInfo.id}`, JSON.stringify(cartfilter))
            setLocalStores(cartfilter)
        }else{
            cart.unshift(x)
            localStorage.setItem(`Cart${userInfo.id}`, JSON.stringify(cart))
            setLocalStores(cart)
        }
    }
    
    const calcPrices = !IsEmpty(localStores) ? localStores.reduce((x,item)=>x + parseInt((item.price).trim()),0).toFixed(2) : null
    const itemsPrices = (!IsEmpty(localStores) && localStores.length >= 5) ? calcPrices - (calcPrices * 0.1 ) : (!IsEmpty(localStores) && localStores.length >= 3) ? calcPrices - (calcPrices * 0.07 ) : (!IsEmpty(localStores) && localStores.length >= 2) ? calcPrices - (calcPrices * 0.05 ) : calcPrices
    const isDiscount = (!IsEmpty(localStores) && localStores.length > 1) ? true : false
    
    const handleCheckOut = ()=>history("/order-summary")
    
    return (
        <div>
            <div>
                <SideBar />
            </div>
            <div className='pt_1'></div>
            <div className='mainContent shadow'>
                <DashboardNav />
                <div className='mainContentDashboardsubs'>
                    <div className="DashboardTicketsContainer">
                        <div>
                            <div className="font_24 bold8">
                                Requests
                            </div>
                            <div className="pt_3">
                                {itemsPrices &&
                                    <div className='priceContainer_Displayer'>
                                        <span className="flex xc text-success">
                                            {process.env.REACT_APP_CURRENCY_SYMBOL}{itemsPrices}
                                        </span>
                                        <span className='flex xc font_12 text-muted'>
                                            {isDiscount && "Discount applied"}
                                        </span>
                                        <button className='left_auto btn-sm btn btn-primary text-white'
                                            onClick={handleCheckOut}
                                        >
                                            Proceed to payment
                                        </button>
                                    </div>
                                }
                                {loading && <Loader /> }
                                {(projects && !IsEmpty(projects)) ? projects.map((x)=>(
                                    <div className="requestOuterContainer" key={x.id}>
                                        <div className="requestInnerContainer shadow_sm">
                                            <div className="reqGriders" onClick={()=>HandlePaymentSelection(x)}>
                                                <div className='padmeReq'>
                                                    <div className="font_14 bold8">
                                                        <ServicesDecode KEYS={x.request.service} />
                                                    </div>
                                                    <div className="font_12 bold6">
                                                        <Lengther amount={76}>
                                                            {x.request.subject}
                                                        </Lengther>
                                                    </div>
                                                    <div className="font_12 text-muted">
                                                        <DateFormater date={x.date} />
                                                    </div>
                                                </div>
                                                <div className='flex alignCenter'>
                                                    <div className={x.reference === "Completed" ? "center w-100 text-success bold6" : x.reference === "Ongoing" ? "center w-100 bold6" : "center w-100 text-muted bold6"}>
                                                        {x.reference}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='selectedReqContainer flex' onClick={()=>HandlePaymentSelection(x)}>
                                                {(x.price === "0" || x.price === "paid") ?
                                                    <span className=' font_12 text-muted'>
                                                        - . -
                                                    </span>
                                                :
                                                    <div className="flex">
                                                        <div className="flex xc">
                                                            <span className={`selectorSm ${!IsEmpty(localStores.filter(m =>m.id === x.id)) ? "bg-primary" : "bg-white"}`}
                                                                // onClick={()=>HandlePaymentSelection(x)}
                                                            ></span>
                                                        </div>
                                                        <img src={`https://img.icons8.com/external-anggara-glyph-anggara-putra/24/${!IsEmpty(localStores.filter(m =>m.id === x.id)) ? "3B71CA" : "6C757D"}/external-cart-retail-anggara-glyph-anggara-putra-4.png`}/>
                                                        <div className="flex xc">
                                                            <span className="font_12 text-success">
                                                                {process.env.REACT_APP_CURRENCY_SYMBOL}{x.price}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="left_auto xcd">
                                                    {(x.is_done || x.reference !== "Ongoing") &&
                                                        <img className='pointer' src="https://img.icons8.com/ios-filled/20/6C757D/delete--v1.png"
                                                            onClick={()=>{
                                                                setSmShow(true)
                                                                setDelVal(x.id)
                                                            }}
                                                        />
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                
                                :
                                    <div>
                                        No request available. <Link to={"/dashboard"}>View dashboard</Link>
                                    </div>
                                }
                            </div>
                        </div>
                        <div>
                            {localStores && !IsEmpty(localStores) && 
                                <div className='orderSummarY'>
                                    <div className="bold7 pb_2">
                                        Check out
                                    </div>
                                    {localStores.map((x)=>(
                                        <div className="requestOuterContainer" key={x.id}>
                                            <div className="requestInnerContainer shadow_sm">
                                                <div className="reqGriders">
                                                    <div className='padmeReq'>
                                                        <div className="font_14 bold8">
                                                            <ServicesDecode KEYS={x.request.service} />
                                                        </div>
                                                        <div className="font_12 bold6">
                                                            <Lengther amount={76}>
                                                                {x.request.subject}
                                                            </Lengther>
                                                        </div>
                                                        <div className="font_12 text-muted">
                                                            <DateFormater date={x.date} />
                                                        </div>
                                                    </div>
                                                    <div className='flex alignCenter'>
                                                        <div className={x.reference === "Completed" ? "center w-100 text-success bold6 primax" : x.reference === "Ongoing" ? "center w-100 bold6 primax" : "center w-100 text-muted bold6 primax"}>
                                                            {x.reference}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='selectedReqContainer flex'>
                                                    

                                                    <div className="left_auto xcd">
                                                        <img className='pointer' src="https://img.icons8.com/ios-filled/20/6C757D/delete--v1.png"
                                                            onClick={()=>{
                                                                HandlePaymentSelection(x)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {itemsPrices &&
                                        <div className='priceContainer_Displayer'>
                                            <span className="flex xc text-success">
                                                {process.env.REACT_APP_CURRENCY_SYMBOL}{itemsPrices}
                                            </span>
                                            <span className='flex xc font_12 text-muted'>
                                                {isDiscount && "Discount applied"}
                                            </span>
                                            <button className='left_auto btn-sm btn bg-primary text-white'
                                                onClick={handleCheckOut}
                                            >
                                                Proceed to payment
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
            <Modal
                size="sm"
                show={smShow}
                onHide={() => {
                    setSmShow(false)
                    setDelVal(-1)
                    dispatch({
                        type: DELETE_PROJECT_RESET,
                    })
                }}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <span className="text-danger font_14 bold6">
                        Are sure you want to delete this request?
                    </span>
                </Modal.Header>
                <Modal.Body>
                    {loadingDel && <Loader />}
                    {!loadingDel && !success && !errorDel &&
                        <button className='btn btn-sm bg-danger w-100 center text-white'
                            onClick={()=>dispatch(handleProjectAction(delVal))}
                        >
                            Delete
                        </button>
                    }
                    {errorDel && 
                        <span className="text-danger font_12">
                            An error occured
                        </span>
                    }
                    {success && 
                        <span className="text-success font_12">
                            Success!
                        </span>
                    }

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MadeRequests