import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import NavBar from './NavBar'
import Message from './Message'
import Loader2 from './Loader2'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction } from './HomeActions'
import { ADD_STUDENT_RESET } from './Constants'

const Login = () => {
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [formType,setFormType] = useState(false)


    const [errorMsg,setErrorMsg] = useState()
    const dispatch = useDispatch()
    const location = useLocation()

    const redirect = location.search ? location.search.split("=")[1]: "/made-requests"

    const userLogin = useSelector(state => state.userLogin)
    const {error,loading,userInfo} =userLogin

    const history = useNavigate()
    

    useEffect(()=>{
        if (userInfo){
            history(redirect)
        }
    },[history,userInfo,redirect,dispatch,loading])

    const submitHandler = (e)=>{
        dispatch({
            type: ADD_STUDENT_RESET,
        })
        e.preventDefault()
        setErrorMsg("")
        if(!password || !email){
            setErrorMsg("Password or email field cannot be empty")
        }else if(password.length <5){
            setErrorMsg("Invalid password or email")
        }else{
            dispatch(loginAction(email,password))
        }
    }
    
  return (
    <div>
        <NavBar />
    
        <div className='loginFormBg'>
            <div className="paddForm">
                <div className="loginContainerBox3">
                    <div className="center bold4 font_24">
                        Login
                    </div>
                    <div className="center text-dark pb_1">
                        Welcome Back
                    </div>
                    {errorMsg && 
                        <Message variant={"danger"} children={errorMsg}></Message>
                    }
                    {error && <Message variant={"danger"} children={"Invalid login credentials. Please check email and password"}></Message>}
                    <input type="text" placeholder='Input Email' 
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                    <div className="passwordFormContainer">
                        <input className='xb' type={formType === false ? "password" : "text"} placeholder='Password' 
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                        <div className='sight'>
                            <img className={formType === false ? "pointer" : "d_none"} src="https://img.icons8.com/external-creatype-outline-colourcreatype/29/null/external-eye-essential-ui-v4-creatype-outline-colourcreatype.png"
                                onClick={()=>setFormType(!formType)}
                            />
                            <img className={formType === false ? "d_none" : "pointer"} src="https://img.icons8.com/external-creatype-glyph-colourcreatype/24/null/external-eyes-basic-creatype-glyph-colourcreatype-2.png"
                                onClick={()=>setFormType(!formType)}
                            />
                        </div>
                    </div>
                    <div>
                        <button onClick={submitHandler}>
                            {loading ? <Loader2 variant={"light"} /> : "Submit"}
                        </button>
                    </div>
                    <div className="pt_1">
                        <Link to={"/forgot-password"} className="text-primary">
                            Forgot password?
                        </Link>
                    </div>
                </div>
            </div>
            {/* <img src="/DelImages/IMG_8220.jpg" alt="" /> */}
        </div>
    </div>
  )
}

export default Login