import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import BookConsultation from './BookConsultation';
import Footer from './Footer'
import NavBar from './NavBar'

const Faqs = () => {
    const [lgShow, setLgShow] = useState(true);
    return (
        <div>
            <NavBar />
            <BookConsultation action={lgShow} />
            <div className='relative'>
                <div className="thirdStandardWidth relative">
                    <div className='pt_5'>
                        <div className="red center bold6 font_12">
                            FAQs
                        </div>
                        <div className="Header40 center">
                            FREQUENTLY ASKED QUESTIONS
                        </div>
                        <div className="pt_3 center">
                            <button className='GeneralBookingBtn'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                }}
                            >
                                Request Service
                            </button>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            <div className="secondStandardWidth">
                <div className="center">
                    <img src="/Images/faqs.png" alt="." />
                </div>
            </div>
            <div className="bg_Services relative">
                <div className="thirdStandardWidth ">
                    <div className="HomeAbout deigns">
                        
                        <div className="Header2 center">
                            FAQs
                        </div>
                        <div className="borderBottoms"></div>
                        
                    </div>
                    <img src="/Backgrounds/rect.svg" alt="." className='square9' />
                    <img src="/Backgrounds/startLight.svg" alt="." className='square10' />
                    <img src="/Backgrounds/square5.svg" alt="." className='square11' />
                    <img src="/Backgrounds/square6.svg" alt="." className='square12' />
                </div>
                <div className="secondStandardWidth">
                    <div className="faqsContentContainer px_5">
                        <div className='coldsf'>
                            <div>
                                <img src="https://img.icons8.com/pulsar-line/48/null/share-2.png"/>
                            </div>
                            <div>
                                <div className="font_15 bold8">
                                    How do I report technical issues or suggest improvements?
                                </div>
                                <div className="pt_1 text-muted font_14">
                                    If you encounter technical issues or have suggestions for improvements, please reach out to our support team at <a href="mailto:services@greyvalour.com">services@greyvalour.com</a>.
                                </div>
                            </div>
                        </div>
                        <div className='coldsf'>
                            <div>
                                <img src="https://img.icons8.com/pulsar-line/48/null/share-2.png"/>
                            </div>
                            <div>
                                <div className="font_15 bold8">
                                    How can I navigate through Grey Valours content?
                                </div>
                                <div className="pt_1 text-muted font_14">
                                    Grey Valours content  is organized into different categories. You can navigate through the content by selecting the relevant category and exploring the resources within.
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="borderBottomss"></div>
                    <div className="faqsContentContainer px_5">
                        <div className='coldsf'>
                            <div>
                                <img src="https://img.icons8.com/pulsar-line/48/null/share-2.png"/>
                            </div>
                            <div>
                                <div className="font_15 bold8">
                                    Can I contribute my own content?
                                </div>
                                <div className="pt_1 text-muted font_14">
                                    At the moment, content contribution is limited to our internal team. However, user contributions will be enabled when our open-source community launches  in the future.
                                </div>
                            </div>
                        </div>
                        <div className='coldsf'>
                            <div>
                                <img src="https://img.icons8.com/pulsar-line/48/null/share-2.png"/>
                            </div>
                            <div>
                                <div className="font_15 bold8">
                                    How long does it take to design a website?
                                </div>
                                <div className="pt_1 text-muted font_14">
                                    The timeline varies based on the complexity of the project. A basic website might take a few weeks, while more intricate sites can take several months.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="borderBottomss"></div>
                    <div className="faqsContentContainer px_5">
                        <div className='coldsf'>
                            <div>
                                <img src="https://img.icons8.com/pulsar-line/48/null/share-2.png"/>
                            </div>
                            <div>
                                <div className="font_15 bold8">
                                    How much does web design cost?
                                </div>
                                <div className="pt_1 text-muted font_14">
                                    Cost varies widely based on factors like complexity, features, and project duration. It's best to get a personalized quote after discussing your project.
                                </div>
                            </div>
                        </div>
                        <div className='coldsf'>
                            <div>
                                <img src="https://img.icons8.com/pulsar-line/48/null/share-2.png"/>
                            </div>
                            <div>
                                <div className="font_15 bold8">
                                    Do you provide SEO services?
                                </div>
                                <div className="pt_1 text-muted font_14">
                                    Yes, We offer comprehensive SEO services
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="HomeAbout xdr"></div>
            </div>
            <Footer />
        </div>
    )
}

export default Faqs