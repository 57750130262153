import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import DashboardNav from './DashboardNav'
import SideBar from './SideBar'
import Collapse from 'react-bootstrap/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardAction, handleProjectAction } from './HomeActions';
import ServicesDecode from './ServicesDecode';
import ListStages from './ListStages';
import Loader from './Loader';
import Modal from 'react-bootstrap/Modal';
import { DELETE_PROJECT_RESET } from './Constants';
import IsEmpty from './IsEmpty';

const Dashboard = () => {
    const [smShow, setSmShow] = useState(false);
    const [project,setProject] = useState("")
    const [open, setOpen] = useState(false);
    
    const [isCopied, setIsCopied] = useState(false);

    const [errorMsg,setErrorMsg] = useState()
    const dispatch = useDispatch()
    const location = useLocation()

    const redirect = location.search ? location.search.split("=")[1]: "/login"

    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin

    const handleProjectDel = useSelector(state=> state.handleProjectDel)
    const {error:errorDel, loading:loadingDel, success} = handleProjectDel

    const dashboard = useSelector(state => state.dashboard)
    const {error,loading,project:project1,projects} =dashboard

    const history = useNavigate()
    
    useEffect(()=>{
        if (!userInfo){
            history(redirect)
        }
    },[history,userInfo,redirect])

    useEffect(()=>{
        dispatch(dashboardAction())
    },[])

    useEffect(()=>{
        if(project1){
            setProject(project1)
        }
    },[])

    const percentageWorkDone = project1 ? `${project1.levels}%` : "1%"
    const url = project1 ? project1.url : "---"
    return (
        <div>
            <div>
                <SideBar />
            </div>
            <div className='pt_1'></div>
            <div className='mainContent shadow'>
                <DashboardNav />
                <div className='mainContentDashboardsubs'>
                    <div className="DashboardTicketsContainer">
                        <div>
                            <div className="projectDashContainers shadow_sm">
                                {projects && !IsEmpty(projects) &&
                                    <select 
                                        value={project}
                                        onChange={(e)=>{
                                            setProject(e.target.value)
                                            dispatch(dashboardAction(e.target.options[e.target.selectedIndex].getAttribute("id")))
                                        }}
                                    >
                                        {projects && projects.map((x)=>(
                                            <option value={x.request.service} key={x.id} id={x.id}>
                                                <ServicesDecode KEYS={x.request.service} />
                                            </option>
                                        ))}
                                        
                                    </select>
                                }
                                {projects && IsEmpty(projects) && 
                                    <span className='text-muted'>
                                        No active project
                                    </span>
                                }
                            </div>
                            
                            {loading ?
                                <Loader /> 
                            :
                                (project1 && !IsEmpty(project1)) ?
                            
                                <div className="progressContentContainer">
                                    <div className="flex">
                                        <div className="bold6">
                                            Progress <span className='Pl_1'>{percentageWorkDone}</span>
                                        </div>
                                        <div className="left_auto">
                                            {project1.is_done ? 
                                                <img className='pointer' src="https://img.icons8.com/ios-filled/28/DC3551/delete--v1.png"
                                                    onClick={()=>setSmShow(true)}
                                                />
                                            :
                                                <img className='pointer' src="https://img.icons8.com/ios-filled/28/bcb5b9/delete--v1.png"/>
                                            }
                                           
                                        </div>
                                    </div>
                                    <Modal
                                        size="sm"
                                        show={smShow}
                                        onHide={() => {
                                            setSmShow(false)
                                            dispatch({
                                                type: DELETE_PROJECT_RESET,
                                            })
                                        }}
                                        aria-labelledby="example-modal-sizes-title-sm"
                                    >
                                        <Modal.Header closeButton>
                                            <span className="text-danger font_14 bold6">
                                                Are sure you want to delete this project?
                                            </span>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {loadingDel && <Loader />}
                                            {!loadingDel && !success && !errorDel &&
                                                <button className='btn btn-sm bg-danger w-100 center text-white'
                                                    onClick={()=>dispatch(handleProjectAction(project1.id))}
                                                >
                                                    Delete
                                                </button>
                                            }
                                            {errorDel && 
                                                <span className="text-danger font_12">
                                                    An error occured
                                                </span>
                                            }
                                            {success && 
                                                <span className="text-success font_12">
                                                    Success!
                                                </span>
                                            }

                                            {success && dispatch(dashboardAction())}
                                        </Modal.Body>
                                    </Modal>

                                    <div className="progressBarContainer">
                                        <div className="progressBar" 
                                            style={{
                                                width: `${percentageWorkDone}`
                                            }}
                                        ></div>
                                    </div>
                                    <div className="pt_1 bold6">
                                        Project Url
                                    </div>
                                    <div className="pt_05 copyIt">
                                        <input type="text" 
                                            value={url}
                                            onChange={()=>{}}
                                        />
                                        <img className='ml_1 pointer' src="https://img.icons8.com/fluency-systems-regular/25/null/clone-figure--v3.png"
                                            onClick={() =>  {
                                                navigator.clipboard.writeText(url)
                                                setIsCopied(true)
                                                setTimeout(()=>setIsCopied(false), 4000)
                                                
                                            }}
                                        />
                                    </div>

                                    {isCopied && 
                                        <div className="text-success font_12 bold6 text_copy_synthax">
                                            Text Copied!
                                        </div>
                                    }
                                    
                                    <div className="pt_5">
                                        <div className="bold6">
                                            Current stage
                                        </div>
                                        <div className="pt_1 border-bottom">
                                            <div className="px_1 pointer" 
                                                onClick={() => setOpen(!open)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={open}
                                            >
                                                <span className='text-primary'>
                                                    {project1 ? project1.current_stage : "...Loading"}
                                                </span>
                                                <Collapse in={open}>
                                                    <div id="example-collapse-text" className='pt_1'>
                                                        {project1 && <ListStages values={project1.list_stages } currentSatge={project1.current_stage} />}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className='progressContentContainer'>
                                    <div className="text-muted">
                                        No request
                                    </div>
                                </div>
                                
                            }
                            
                        </div>
                        <div>
                            <div className="flex pb_2">
                                <span className="font_20 bold6">
                                    Special Packages
                                </span>
                                <Link to={"/packages"} className="blue left_auto font_12 pt_05 bold7">
                                    View all
                                </Link>
                            </div>
                            <Link to={"/packages"}>
                                <div className="ticketsHover">
                                    <div className="tickets rbk">
                                        <div className="font_15 bold7 colrbk">
                                            BASIC
                                        </div>
                                        <div className="font_14 text-muted">
                                            UI/UX Design , Brand Identity Design ...
                                        </div>
                                        <div className="font_12 bold6 text-muted">
                                            4-5% Discount
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={"/packages"}>
                                <div className="ticketsHover">
                                    <div className="tickets lbk">
                                        <div className="font_15 bold7 collbk">
                                            STANDARD
                                        </div>
                                        <div className="font_14 text-muted">
                                            Brand Identity Design, Web development ...
                                        </div>
                                        <div className="font_12 bold6 text-muted">
                                            6-7% Discount
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={"/packages"}>
                                <div className="ticketsHover">
                                    <div className="tickets">
                                        <div className="font_15 bold7">
                                            PREMIUM
                                        </div>
                                        <div className="font_14 text-muted">
                                            Mobile development,Database Management ...
                                        </div>
                                        <div className="font_12 bold6 text-muted">
                                            9-10% Discount
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Dashboard