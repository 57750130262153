import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { passwordSetAction, tokenRequestAction } from './HomeActions';
import Message from './Message';
import Loader2 from './Loader2';
import NavBar from './NavBar';
import Loader from './Loader';


const SetUpaccount = () => {
    const token = useParams();
    const dispatch= useDispatch()
    const history = useNavigate()
    const location =useLocation()
    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin

    const passwordSet = useSelector(state=> state.passwordSet)
    const {loading,error,success} = passwordSet
    
    const requestTokenAction = useSelector(state=> state.requestTokenAction)
    const {loading:loadingToken,error:errorToken,success:successToken} = requestTokenAction

    const [errorPass,setErrorPass] = useState(false)

    const [formType2,setFormType2] = useState(false)
    const [formType,setFormType] = useState(false)

    const [password,setPassword] = useState("")
    const [confirm_password,setConfirm_password] = useState("")
    const [errorMsg,setErrorMsg] = useState()
    const redirect = location.search ? location.search.split("=")[1]: "/"
    function password_validate(p) {
        return new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})").test(p)
    }
    const submitHandler = (e)=>{
        e.preventDefault()
        setErrorMsg("")
        setErrorPass(false)
        if(password !== confirm_password){
            setErrorMsg("Password field did not match")
        }else if(password.length <5){
            setErrorMsg("Password must be more than 5 characters long")
        }else if(!password_validate(password)){
            setErrorPass(true) 
        }else{
            dispatch(passwordSetAction({"password": password,"token": token.token}))
        }
    }
    useEffect(()=>{
        if(userInfo){
            history(redirect)
        }
        if (!token.token){
            setErrorMsg("Invalid token")
        }
        setErrorPass(false)
    },[userInfo,error,history,dispatch,token,redirect])

    const requestTokenAgain = ()=>{
        dispatch(tokenRequestAction({email:token.email}))
    }

    return (
        <div>
            <NavBar />
            <div className='loginFormBg'>
                <div className="paddForm">
                    <div className="loginContainerBox3">
                        {(success && !successToken) ?
                            <div>
                                <div className="center bold4 font_24 text-success">
                                    Success!
                                </div>
                                <div className="center text-dark pb_1 font_14">
                                    Account set up was successful. 
                                    You can login to keep track of your requests.
                                </div>
                            </div>
                        :
                            (successToken && !success) ?
                            <div>
                                <div className="center bold4 font_24 text-success">
                                    Success!
                                </div>
                                <div className="center text-dark pb_1 font_14">
                                    Email with instructions of how to set up 
                                    your account have been sent to your mail.
                                </div>
                            </div>
                        :
                            <div>
                                <div className="center bold4 font_24">
                                    You almost ready to start
                                </div>
                                <div className="center text-dark pb_1 font_14">
                                    Set up your password.
                                </div>
                            </div>
                        }
                        
                        {errorMsg ?
                            <Message variant={"danger"} children={errorMsg}></Message>
                        :
                            errorPass ?
                            <div>
                                <Message variant={"danger"}>
                                    <ul>
                                        <li className='font_11'>
                                            Password must contain at least 1 lowercase alphabetical character
                                        </li>
                                        <li className='font_11'>
                                            Password must contain at least 1 uppercase alphabetical character
                                        </li>
                                        <li className='font_11'>
                                            Password must contain at least 1 numeric character
                                        </li>
                                        <li className='font_11'>
                                            Password must contain at least one special character
                                        </li>
                                        <li className='font_11'>
                                            Password must be eight characters or longer
                                        </li>
                                    </ul>
                                </Message>
                            </div>
                        :
                            (!errorToken && error && !success && !successToken) ?
                            <div>
                                <Message variant={"warning"}>
                                    <span className="font_12">
                                        
                                        <span>
                                            Invalid or expired token. Please request 
                                            {loadingToken ? 
                                                <span className='text-muted px-1 pointer'>
                                                    account set up
                                                </span> 
                                            
                                            :
                                                <span className='text-primary px-1 pointer'
                                                    onClick={requestTokenAgain}
                                                >
                                                    account set up
                                                </span> 
                                            }
                                            
                                            again.
                                        </span>
                                    </span>    
                                </Message>
                                
                            </div>
                        :
                            errorToken ?
                            <div>
                                <Message variant={"danger"}>
                                    <span className="font_12">
                                        {loadingToken ? <Loader variant={"light"} /> :
                                            <span>
                                                Invalid token.
                                            </span>
                                        }
                                        
                                    </span>    
                                </Message>
                                
                            </div>
                        : ""}
                        {!success && !successToken &&
                            <div>
                                <div className='EmailSetUpContainer center'>
                                    {token.email}
                                </div>
                                <div className="passwordFormContainer">
                                    <input className='xb' type={formType === false ? "password" : "text"} placeholder='Password' 
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}
                                    />
                                    <div className='sight'>
                                        <img className={formType === false ? "pointer" : "d_none"} src="https://img.icons8.com/external-creatype-outline-colourcreatype/29/null/external-eye-essential-ui-v4-creatype-outline-colourcreatype.png"
                                            onClick={()=>setFormType(!formType)}
                                        />
                                        <img className={formType === false ? "d_none" : "pointer"} src="https://img.icons8.com/external-creatype-glyph-colourcreatype/24/null/external-eyes-basic-creatype-glyph-colourcreatype-2.png"
                                            onClick={()=>setFormType(!formType)}
                                        />
                                    </div>
                                </div>
                                <div className="passwordFormContainer">
                                    <input className='xb' type={formType2 === false ? "password" : "text"} placeholder='Confirm Password' 
                                        value={confirm_password}
                                        onChange={(e)=>setConfirm_password(e.target.value)}
                                    />
                                    <div className='sight'>
                                        <img className={formType2 === false ? "pointer" : "d_none"} src="https://img.icons8.com/external-creatype-outline-colourcreatype/29/null/external-eye-essential-ui-v4-creatype-outline-colourcreatype.png"
                                            onClick={()=>setFormType2(!formType2)}
                                        />
                                        <img className={formType2 === false ? "d_none" : "pointer"} src="https://img.icons8.com/external-creatype-glyph-colourcreatype/24/null/external-eyes-basic-creatype-glyph-colourcreatype-2.png"
                                            onClick={()=>setFormType2(!formType2)}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        { !successToken && 
                            <div>
                                {(success) ? 
                                    <button onClick={()=>history("/login")}>
                                        Login to account
                                    </button>
                                :
                                    <button onClick={submitHandler}>
                                        {loading ? <Loader2 variant={"light"} /> : "Submit"}
                                    </button>
                                }
                                
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetUpaccount