import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import BookConsultation from './BookConsultation';
import { contactUsAction, logoutAction } from './HomeActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import Logo from './Logo';

const NavBar = () => {
    const [lgShow, setLgShow] = useState(true);
    const [lgShows, setLgShows] = useState(false);

    const [message, setMessage] = useState("")
    const [subject, setSubject] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [bookConsult, setBookConsult] = useState(1)
    
    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin


    const [scrollTop, setScrollTop] = useState(0);
    useEffect(() => {
        const handleScroll = event => {
          setScrollTop(window.scrollY);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [navctrl, setNavctrl] = useState(false)
  
    function useNavbar(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                setShow2(false)
                setShow(false)
                setNavctrl(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useNavbar(wrapperRef);
    
    // Contact us began here
    const dispatch= useDispatch()
    const [checkForm, setCheckForm] = useState(false)
    const contactUs = useSelector(state => state.contactUs)
    const {loading,error,info,success} = contactUs

    const submitHandler=()=>{
        if(!name || !email || !message || !subject){
            setCheckForm(true)
        }else{
            setCheckForm(false)
            setBookConsult(bookConsult + 1)
            dispatch(contactUsAction({
                name,email,
                subject,message
            }))
        }
    }

      

  return (
    <div className={scrollTop >10 ? 'MyNav sticky_top bg_white' : 'MyNav sticky_top' } ref={wrapperRef}>
        <Modal
            size="lg"
            show={lgShows}
            onHide={() => setLgShows(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <div className="flex">
                    <div>
                        <img src="https://img.icons8.com/bubbles/43/null/ringer-volume.png"/>
                    </div>
                    <div className='pl_1'>
                        <div className="pt_1 bold5">
                            Contact us
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="relative">
                    <div className={bookConsult === 1 ? "modalBodyy" : "d_none"}>
                        <div className="activities xrc">
                            <span className='active'></span>
                        </div>
                        <div className="mdalContent">
                            <div className="bold6 font_18">
                                We will love to hear from you
                            </div>
                            <div className="pt_1 DForm">
                                <input type="text" placeholder='Input Full Name'
                                    className={(checkForm && !name) ? "xdForm" : ""}
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                />
                                <input type="email" placeholder='Input Contact Email'
                                    className={(checkForm && !email) ? "xdForm" : ""}
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                                <input type="text" placeholder='Subject'
                                    className={(checkForm && !subject) ? "xdForm" : ""}
                                    value={subject}
                                    onChange={(e)=>setSubject(e.target.value)}
                                />
                                <textarea placeholder='Type here'
                                    className={(checkForm && !message) ? "xdForm" : ""}
                                    value={message}
                                    onChange={(e)=>setMessage(e.target.value)}
                                ></textarea>
                            </div>
                            
                        </div>
                    </div>
                    <div className={bookConsult === 2 ? "modalBodyy" : "d_none"}>
                        <div className="activities"></div>
                        <div className="mdalContent">
                            { loading ? 
                                <Loader />
                            :
                                <div>
                                    {error ? 
                                        <div>
                                            <div className="bold8 text-danger center">
                                                Unsuccessful
                                            </div>
                                            <div className="pt_2 font_14">
                                                There was an error with your request.
                                                Please try again later.
                                            </div>
                                        </div>
                                    :
                                        <div>
                                            <div className="bold8 text-success center">
                                                Your message was submitted successfully
                                            </div>
                                            <div className="pt_2 font_14">
                                                Your message was received. 
                                                You will get a response within 24hrs of 
                                                your request.
                                            </div>
                                            
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="mdalContent">
                        <div className={bookConsult === 1 ? "pb_3 flex" : "d_none"}>
                            <button className='NavRequestServicesBtn  ' onClick={()=>{
                                submitHandler()
                            }}>
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className=" px_2 flex xdc">
                        <div className='flex center2 xdccx'>
                            <div className='flex'>
                                <div>
                                    <img src="https://img.icons8.com/laces/40/null/secured-letter.png"/>
                                </div>
                                <div className='pt_05'>
                                    <a href="mailto:services@greyvalor.com" className='text-dark pl_1'>
                                        services@greyvalor.com
                                    </a>
                                </div>
                            </div>
                            <div className='flex pl_5'>
                                <div>
                                    <img src="https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/40/null/external-phone-customer-service-xnimrodx-lineal-xnimrodx-3.png"/>
                                </div>
                                <div className='pt_05'>
                                    <a href="tel:+2348160055931" className='text-dark pl_1'>
                                        +2348160055931
                                    </a>
                                </div>
                            </div>
                            <div className='pl_3'>
                                <Link to={"/home"} className="px_1">
                                    <img src="/Backgrounds/Instagram.svg" alt="." />
                                </Link>
                                <Link to={"/home"} className="px_1">
                                    <img src="/Backgrounds/facebook.svg" alt="." />
                                </Link>
                                <Link to={"/home"} className="px_1">
                                    <img src="/Backgrounds/twitter.svg" alt="." />
                                </Link>
                                <Link to={"/home"} className="px_1">
                                    <img src="/Backgrounds/youtube.svg" alt="." />
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Modal.Body>
        </Modal>
        <div className="flex firstStandardWidth">
            <div className="logo_container">
                <Logo nav />
            </div>
            <div className='NavLinksContainer'>
                
                <Link to={"/home"} className="text-dark NavLink">
                    Home
                </Link>
                <Link to={"/about"} className="text-dark NavLink">
                    About Us
                </Link>
                <Link to={"/services"} className="text-dark NavLink">
                    Services
                </Link>
                <Link to={"/packages"} className="text-dark NavLink">
                    Packages
                </Link>
                <span className="text-dark NavLink pointer" 
                    onClick={() =>{
                        setBookConsult(1)
                        setLgShows(true)
                    }}
                >
                    Contact Us
                </span>
                <samp className='BreadCrumbs'>
                    <img src="https://img.icons8.com/ios-glyphs/30/null/menu--v1.png"
                        onClick={()=>setNavctrl(!navctrl)}
                    />
                </samp>
                {userInfo ?
                    <div className='hover_Effect'>
                        <Link to={"/dashboard"} className=" NavLink xr">
                            Dashboard
                        </Link>
                        <div className="hover_Effect2">
                            <div className="drop_Down_containers">
                                <span onClick={()=>dispatch(logoutAction(userInfo ? userInfo.email : ""))} className='font_14 bold6 text-primary pointer'>
                                    Logout
                                </span>
                            </div>
                        </div>
                    </div>
                :
                    <Link to={"/login"} className=" NavLink xr">
                        Login
                    </Link>
                }
                
                <button className='NavRequestServicesBtn'
                    onClick={() => {
                        setLgShow(!lgShow)
                    }}
                >
                    Request Service
                </button>
            </div>
        </div>
        {navctrl ? (
            <div className="bread_crumbs flex fixed-top">
                <div className='drop_down_container'>
                    <div className="flex inner_bread_Crumbs">
                        <img src="/Backgrounds/cancelnav.svg" alt="x"
                        className='left_auto'
                        onClick={()=>setNavctrl(!navctrl)}
                        />
                    </div>
                    <div className='navListItems'>
                        
                        
                        <div id="xd">
                            <div className='xdd center'>
                                <Link to="/home" onClick={()=>setNavctrl(!navctrl)} >
                                    Home
                                </Link>
                            </div>
                            <div className='xdd center'>
                                <Link to="/about"
                                onClick={()=>setNavctrl(!navctrl)}
                                >
                                    About Us
                                </Link>
                            </div>
                            <div className='xdd center'>
                                <Link to="/services"
                                onClick={()=>setNavctrl(!navctrl)}
                                >
                                    Services
                                </Link>
                            </div>
                            <div className='xdd center'>
                                <Link to="/packages"
                                onClick={()=>setNavctrl(!navctrl)}
                                >
                                    Packages
                                </Link>
                            </div>
                            {userInfo &&
                                <div>
                                    <div className='xdd center'>
                                        <Link to={"/dashboard"}>
                                            Dashboard
                                        </Link>
                                    </div>
                                    <div className='xdd center'>
                                        <Link to={"/made-requests"}>
                                            Requests
                                        </Link>
                                    </div>
                                    <div className='xdd center'>
                                        <Link to={"/profile"}>
                                            Profile
                                        </Link>
                                    </div>
                                    <div className='xdd center'>
                                        <Link to={"/settings"}>
                                            Settings
                                        </Link>
                                    </div>
                                </div>
                            }
                            {userInfo ?
                                <div className='xdd center'>
                                    <span 
                                        onClick={()=>dispatch(logoutAction(userInfo ? userInfo.email : ""))} 
                                        className='text-white'
                                    >
                                        Logout
                                    </span>
                                </div>
                            :
                                <div className='xdd center'>
                                    <Link to="/login"
                                    onClick={()=>setNavctrl(!navctrl)}
                                    >
                                        Login
                                    </Link>
                                </div>
                            }
                            <div className="xdd center">
                                <span
                                    onClick={() => {
                                        setLgShow(!lgShow)
                                    }}
                                    className='text-white'
                                >
                                    Request Service
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : ""}
        <BookConsultation action={lgShow} />
    </div>
  )
}

export default NavBar