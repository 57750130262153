import React, { useState } from 'react';
import NavBar from './NavBar'
import Footer from './Footer'
import BookConsultation from './BookConsultation';
import { Link } from 'react-router-dom';

const About = () => {
    const [lgShow, setLgShow] = useState(true);
  return (
    <div>
        <NavBar />
        <div className="bg_Services relative">
            <div className="thirdStandardWidth ">
                <div className="HomeAbout xdr">
                    <div className="red center bold6 font_12">
                        Grey Valor
                    </div>
                    <div className="Header2 center">
                        About Us
                    </div>
                    <div className="pt_3 justify_me">
                        We are a team of technology experts with technical skills with the mindset to create digital tech solutions for companies and establishments that require such services. We aim to build an open-source community with innovative resources for freelancers and tech enthusiasts.
                        
                    </div>
                    <div className="borderBottoms"></div>
                    
                </div>
                <img src="/Backgrounds/rect.svg" alt="." className='square9' />
                <img src="/Backgrounds/startLight.svg" alt="." className='square10' />
                <img src="/Backgrounds/square5.svg" alt="." className='square11' />
                <img src="/Backgrounds/square6.svg" alt="." className='square12' />
            </div>
            <div className="secondStandardWidth">
                <div className="ServicesContainersAbout px_5">
                    <div>
                        <img src="/Images/aboutUs.jpg" alt="." />
                    </div>
                    <div className='aboutUsDetails'>
                        <div className="font_26 bold7">
                            More
                        </div>

                        <div className="pt_2 font_14 justify_me">
                            We are young creatives passionate about creating digital support to promote start-ups and companies with services tailored to capture the needs of these companies and tell their unique story. 
                        </div>
                        <div className='pt_1 font_14 justify_me'>
                            Likewise, we want to grow an online space where tech-savvy experts, neophytes and business owners can collaborate and share ideas freely. 
                        </div>
                        <div className="pt_2">
                            <button className='NavRequestServicesBtn'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                }}
                            >
                                Book Consultation
                            </button>
                        </div>
                    </div>
                </div>
                <div className="borderBottomss"></div>
                <div className="px_5 flex xdc ">
                    <div className='flex center2 xdcc'>
                        <div className='flex'>
                            <div>
                                <img src="https://img.icons8.com/laces/40/null/secured-letter.png"/>
                            </div>
                            <div className='pt_05'>
                                <a href="mailto:services@greyvalor.com" className='text-dark pl_1'>
                                    services@greyvalor.com
                                </a>
                            </div>
                        </div>
                        <div className='flex pl_5'>
                            <div>
                                <img src="https://img.icons8.com/external-xnimrodx-lineal-xnimrodx/40/null/external-phone-customer-service-xnimrodx-lineal-xnimrodx-3.png"/>
                            </div>
                            <div className='pt_05'>
                                <a href="tel:+2348160055931" className='text-dark pl_1'>
                                    +2348160055931
                                </a>
                            </div>
                        </div>
                        <div className='pl_3'>
                            <Link to={"/home"} className="px_1">
                                <img src="/Backgrounds/Instagram.svg" alt="." />
                            </Link>
                            <Link to={"/home"} className="px_1">
                                <img src="/Backgrounds/facebook.svg" alt="." />
                            </Link>
                            <Link to={"/home"} className="px_1">
                                <img src="/Backgrounds/twitter.svg" alt="." />
                            </Link>
                            <Link to={"/home"} className="px_1">
                                <img src="/Backgrounds/youtube.svg" alt="." />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="HomeAbout xdr"></div>
        </div>
        <BookConsultation action={lgShow} />
        <Footer />
    </div>
  )
}

export default About