import {createStore, combineReducers, applyMiddleware} from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { addStudentReducer, bookConsultationReducer, certReducer, contactUsReducer, dashboardReducer, handleProjectReducer, homeListReducer, newsLetterReducer, passwordResetReducer, passwordSetReducer, paymentReducer, settingsReducer, signUpReducer, tokenRequestReducer, tutorialReducer, tutorial_detailReducer, userLoginReducer } from "./HomeReducer"


const reducer = combineReducers({
    homeList : homeListReducer,
    // allCourses: allCourseListReducer,
    userLogin: userLoginReducer,
    singUp: signUpReducer,
    bookConsultation : bookConsultationReducer,
    contactUs:contactUsReducer,
    newsLetter:newsLetterReducer,
    dashboard:dashboardReducer,
    paymentVerification:paymentReducer,
    settings:settingsReducer,
    passwordReset : passwordResetReducer,
    handleProjectDel:handleProjectReducer,
    requestTokenAction:tokenRequestReducer,
    tutorial:tutorialReducer,
    tutorialDetail : tutorial_detailReducer,
    addStudent : addStudentReducer,
    passwordSet : passwordSetReducer,
    certificate : certReducer
})

const userInfoFromStorage = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null

const initialState = {
    userLogin: {userInfo: userInfoFromStorage},
}
const middleware = [thunk]

const store = createStore(reducer, initialState,composeWithDevTools(applyMiddleware(...middleware)))


export default store




