import axios from "axios"
import { ADD_STUDENT_FAIL, ADD_STUDENT_REQUEST, ADD_STUDENT_SUCCESS, BOOK_CONSULTATION_FAIL, BOOK_CONSULTATION_REQUEST, BOOK_CONSULTATION_SUCCESS, COMMENTS_LIST_FAIL, COMMENTS_LIST_REQUEST, COMMENTS_LIST_SUCCESS, CONTACT_US_FAIL, CONTACT_US_REQUEST, CONTACT_US_SUCCESS, DASHBOARD_DATA_FAIL, DASHBOARD_DATA_REQUEST, DASHBOARD_DATA_SUCCESS, DELETE_PROJECT_FAIL, DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, NEWS_LETTER_FAIL, NEWS_LETTER_REQUEST, NEWS_LETTER_SUCCESS, PASSWORD_RESET_FAIL, PASSWORD_RESET_REQUEST, PASSWORD_RESET_SUCCESS, PASSWORD_SET_FAIL, PASSWORD_SET_REQUEST, PASSWORD_SET_SUCCESS, PAYMENT_CHECK_FAIL, PAYMENT_CHECK_REQUEST, PAYMENT_CHECK_SUCCESS, RESET_TOKEN_FAIL, RESET_TOKEN_REQUEST, RESET_TOKEN_SUCCESS, SETTINGS_FAIL, SETTINGS_REQUEST, SETTINGS_SUCCESS, TUTORIALS_DETAILS_FAIL, TUTORIALS_DETAILS_REQUEST, TUTORIALS_DETAILS_SUCCESS, TUTORIALS_FAIL, TUTORIALS_REQUEST, TUTORIALS_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_PROFILE_RESET, USER_REGISTER_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, VERIFY_N_VIEW_CERT_FAIL, VERIFY_N_VIEW_CERT_REQUEST, VERIFY_N_VIEW_CERT_SUCCESS } from "./Constants";


export const addStudentAction =(x) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: ADD_STUDENT_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}`}
        };
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/register_student`,
            x,
            userInfo ? config : headers
        )
        dispatch({
            type: ADD_STUDENT_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : ADD_STUDENT_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}

  
export const certAction =(x) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: VERIFY_N_VIEW_CERT_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}`}
        };
        const headers= {
            "Content-type" : "application/json"
        }
        
        const {data} = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/view_n_verify_cert/${x}`,
            userInfo ? config : headers
        )
        dispatch({
            type: VERIFY_N_VIEW_CERT_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : VERIFY_N_VIEW_CERT_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}


export const homeLists =() => async(dispatch,getState) =>{
    try{
        dispatch({type: COMMENTS_LIST_REQUEST})
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/home`,
            headers
        )
        dispatch({
            type: COMMENTS_LIST_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : COMMENTS_LIST_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}

   
export const tutorial_detailAction =(x) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: TUTORIALS_DETAILS_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}`}
        };
        const headers= {
            "Content-type" : "application/json"
        }
        
        const {data} = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/tutorial_detail/${x}`,
            userInfo ? config : headers
        )
        dispatch({
            type: TUTORIALS_DETAILS_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : TUTORIALS_DETAILS_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}


export const tutorialAction =() => async(dispatch,getState) =>{
    
    try{
        dispatch({type: TUTORIALS_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}` }
        };
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/all_tutorial`,
            config
            )
        dispatch({
            type: TUTORIALS_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : TUTORIALS_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}



export const loginAction =(email,password) => async(dispatch) =>{
    
    try{
        dispatch({type: USER_LOGIN_REQUEST})
        
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/login`,
            {username:email,password:password},
            headers
        )
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })
        localStorage.setItem("userInfo",JSON.stringify(data))
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : USER_LOGIN_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}



export const signUpAction =(x) => async(dispatch) =>{
    
    try{
        dispatch({type: USER_REGISTER_REQUEST})
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/register`,
            x,
            headers
        )
        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })
        
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch(error){
        dispatch({
            type : USER_REGISTER_FAIL,
            payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
        })
    }
}



export const bookConsultationAction =(x) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: BOOK_CONSULTATION_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}`}
        };
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/book_consultation`,
            x,
            userInfo ? config : headers
        )
        dispatch({
            type: BOOK_CONSULTATION_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : BOOK_CONSULTATION_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}




export const handleProjectAction =(id) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: DELETE_PROJECT_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}` }
        };
        const {data} = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/del_project/${id}`,
            config
            )
        dispatch({
            type: DELETE_PROJECT_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : DELETE_PROJECT_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}



export const dashboardAction =(id= -1) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: DASHBOARD_DATA_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}` }
        };
        const {data} = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/dashboard/${id}`,
            config
            )
        dispatch({
            type: DASHBOARD_DATA_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : DASHBOARD_DATA_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}



export const contactUsAction =(x) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: CONTACT_US_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}`}
        };
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/contact`,
            x,
            userInfo ? config : headers
        )
        dispatch({
            type: CONTACT_US_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : CONTACT_US_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}



export const newsLetterAction =(x) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: NEWS_LETTER_REQUEST})
        
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/newsletter`,
            x,
            headers
        )
        dispatch({
            type: NEWS_LETTER_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : NEWS_LETTER_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}

export const logoutAction =(email="") => async(dispatch) =>{
    try{
        dispatch({type: USER_LOGOUT})
        dispatch({type: USER_PROFILE_RESET})
        // const headers= {
        //     "Content-type":"application/json"
        // }
        // try{
        //     const {data} = await axios.post(
        //         `${process.env.REACT_APP_BASE_URL}/api/logout`,
        //         {email},
        //         headers
        //     )
        // }catch{}
        localStorage.removeItem('userInfo')
    } catch(error){
    }
}



export const settingsAction =(x) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: SETTINGS_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}`}
        };
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/settings`,
            x,
            config
        )
        dispatch({
            type: SETTINGS_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })
        
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : SETTINGS_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}



export const paymentAction =(x) => async(dispatch,getState) =>{
    
    try{
        dispatch({type: PAYMENT_CHECK_REQUEST})
        const {userLogin:{userInfo},} =getState()
        const config = {
            headers: { Authorization: `Bearer ${userInfo ? userInfo.token : ''}`}
        };
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/verify_payment`,
            x,
            config
        )
        dispatch({
            type: PAYMENT_CHECK_SUCCESS,
            payload: data
        })
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : PAYMENT_CHECK_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}



export const passwordResetAction =(email) => async(dispatch) =>{
    
    try{
        dispatch({type: PASSWORD_RESET_REQUEST})
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/reset_request`,
            {"email":email},
            headers
        )
        dispatch({
            type: PASSWORD_RESET_SUCCESS,
            payload: data
        })
        
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : PASSWORD_RESET_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}



export const passwordSetAction =(x) => async(dispatch) =>{
    
    try{
        dispatch({type: PASSWORD_SET_REQUEST})
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/reset_token`,
            x,
            headers
        )
        dispatch({
            type: PASSWORD_SET_SUCCESS,
            payload: data
        })
        
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : PASSWORD_SET_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}



export const tokenRequestAction =(x) => async(dispatch) =>{
    
    try{
        dispatch({type: RESET_TOKEN_REQUEST})
        const headers= {
            "Content-type":"application/json"
        }
        const {data} = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/account_token`,
            x,
            headers
        )
        dispatch({
            type: RESET_TOKEN_SUCCESS,
            payload: data
        })
        
    } catch(error){
        if(error && error.response && error.response.status === 401){
            dispatch({type: USER_LOGOUT})
            localStorage.removeItem('userInfo')
        }else{
            dispatch({
                type : RESET_TOKEN_FAIL,
                payload : error.response && error.response.data.detail ? error.response.data.detail : error.message
            })
        }
    }
}


