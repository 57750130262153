import React from 'react'
import './App.css';
import Home from './Home';
import ScrollToTop from './ScrollToTop';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import About from './About';
import Service from './Service';
import Packages from './Packages';
import Login from './Login';
import Dashboard from './Dashboard';
import Profile from './Profile';
import MadeRequests from './MadeRequests';
import Settings from './Settings';
import Designs from './Designs';
import DigitalMarketing from './DigitalMarketing';
import MentorshipTutorship from './MentorshipTutorship';
import Faqs from './Faqs';
import OrderSummary from './OrderSummary';
import ForgotPassword from './ForgotPassword';
import SetPassword from './SetPassword';
import NewPassword from './NewPassword';
import SetUpaccount from './SetUpaccount';
import PreviousProjects from './PreviousProjects';
import LiveTutorial from './LiveTutorial';
import TutorialDetails from './TutorialDetails';
import Certificate from './Certificate';

function App() {
    return (
        <div className="App">
            <Router>
                <ScrollToTop />
                <Routes>
					
                    {/* Home */}
					<Route path="/" element ={<Home/>} />
					<Route path="/home" element ={<Home/>} />
					<Route path="/about" element ={<About/>} />
					<Route path="/services" element ={<Service/>} />
					<Route path="/packages" element ={<Packages/>} />
					<Route path="/login" element ={<Login/>} />
					<Route path="/dashboard" element ={<Dashboard/>} />
					<Route path="/profile" element ={<Profile/>} />
					<Route path="/made-requests" element ={<MadeRequests/>} />
					<Route path="/settings" element ={<Settings/>} />
					<Route path="/order-summary" element ={<OrderSummary/>} />
					<Route path="/design" element ={<Designs/>} />
					<Route path="/certificate/:id" element ={<Certificate />} />
					<Route path="/faqs" element ={<Faqs/>} />
					<Route path="/tutorials" element ={<LiveTutorial />} />
					{/* <Route path="/recent-projects" element ={<PreviousProjects />} /> */}
					<Route path="/forgot-password" element ={<ForgotPassword/>} />
					<Route path="/reset-password/request-sent" element ={<SetPassword/>} />
					<Route path="/tutorial-detail/:id" element ={<TutorialDetails />} />
					<Route path="/reset-password/reset/:token" element ={<NewPassword/>} />
					<Route path="/set-up/:token" element ={<SetUpaccount/>} />
					<Route path="/set-up/:token/:email" element ={<SetUpaccount/>} />
					<Route path="/mentorship/tutorship" element ={<MentorshipTutorship/>} />
					<Route path="/digital-marketing" element ={<DigitalMarketing/>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
