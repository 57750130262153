import React from 'react'
import DashboardNav from './DashboardNav'
import SideBar from './SideBar'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const Profile = () => {
    const history = useNavigate()
    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin
    const location = useLocation()

    const redirect = location.search ? location.search.split("=")[1]: "/login"
    useEffect(()=>{
        if (!userInfo){
            history(redirect)
        }
    },[history,userInfo,redirect])

    return (
        <div>
            <div>
                <SideBar />
            </div>
            <div className='pt_1'></div>
            <div className='mainContent shadow'>
                <DashboardNav />
                <div className='mainContentDashboardsubs'>
                    <div className="DashboardTicketsContainer">
                        <div>
                            <div className="font_24 bold8">
                                My Profile
                            </div>
                            <div className="profileUserContainer">
                                {userInfo && 
                                    <div className="shadow innerUserProfile">
                                        <div className="divideProfileInfo">
                                            <div className="bold8">
                                                Name
                                            </div>
                                            <div className="font_14 bold5">
                                                {userInfo.first_name}
                                            </div>
                                        </div>
                                        <div className="divideProfileInfo">
                                            <div className="bold8">
                                                Email
                                            </div>
                                            <div className="font_14 bold5">
                                                {userInfo.email}
                                            </div>
                                        </div>
                                        <div className="divideProfileInfo">
                                            <div className="bold8">
                                                Phone
                                            </div>
                                            <div className="font_14 bold5">
                                                {userInfo.last_name}
                                            </div>
                                        </div>
                                        <div className="divideProfileInfo">
                                            <div className="bold8">
                                                Password
                                            </div>
                                            <div className="font_14 bold5">
                                                **********
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div></div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Profile