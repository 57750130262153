import { ADD_STUDENT_FAIL, ADD_STUDENT_REQUEST, ADD_STUDENT_RESET, ADD_STUDENT_SUCCESS, BOOK_CONSULTATION_FAIL, BOOK_CONSULTATION_REQUEST, BOOK_CONSULTATION_RESET, BOOK_CONSULTATION_SUCCESS, COMMENTS_LIST_FAIL, COMMENTS_LIST_REQUEST, COMMENTS_LIST_SUCCESS, CONTACT_US_FAIL, CONTACT_US_REQUEST, CONTACT_US_SUCCESS, DASHBOARD_DATA_FAIL, DASHBOARD_DATA_REQUEST, DASHBOARD_DATA_SUCCESS, DELETE_PROJECT_FAIL, DELETE_PROJECT_REQUEST, DELETE_PROJECT_RESET, DELETE_PROJECT_SUCCESS, NEWS_LETTER_FAIL, NEWS_LETTER_REQUEST, NEWS_LETTER_SUCCESS, PASSWORD_RESET_FAIL, PASSWORD_RESET_REQUEST, PASSWORD_RESET_RESET, PASSWORD_RESET_SUCCESS, PASSWORD_SET_FAIL, PASSWORD_SET_REQUEST, PASSWORD_SET_SUCCESS, PAYMENT_CHECK_FAIL, PAYMENT_CHECK_REQUEST, PAYMENT_CHECK_SUCCESS, PAYMENT_RESET, RESET_TOKEN_FAIL, RESET_TOKEN_REQUEST, RESET_TOKEN_SUCCESS, SETTINGS_FAIL, SETTINGS_REQUEST, SETTINGS_RESET, SETTINGS_SUCCESS, TUTORIALS_DETAILS_FAIL, TUTORIALS_DETAILS_REQUEST, TUTORIALS_DETAILS_SUCCESS, TUTORIALS_FAIL, TUTORIALS_REQUEST, TUTORIALS_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_PROFILE_RESET, USER_REGISTER_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, VERIFY_N_VIEW_CERT_FAIL, VERIFY_N_VIEW_CERT_REQUEST, VERIFY_N_VIEW_CERT_SUCCESS } from "./Constants"

export const addStudentReducer =(state={},action)=>{
    switch(action.type){
        case ADD_STUDENT_REQUEST:
            return {loading:true,...state}
        case ADD_STUDENT_SUCCESS:
            return {loading:false, message : action.payload}
        case ADD_STUDENT_FAIL:
            return {loading: false,error:action.payload}
        case ADD_STUDENT_RESET:
            return {}
        default:
            return state
    }
}


export const homeListReducer =(state={},action)=>{
    switch(action.type){
        case COMMENTS_LIST_REQUEST:
            return {loading:true,...state}
        case COMMENTS_LIST_SUCCESS:
            return {loading:false, messages:action.payload}
        case COMMENTS_LIST_FAIL:
            return {loading: false,error:action.payload}
        default:
            return state
    }
}

export const handleProjectReducer =(state={},action)=>{
    switch(action.type){
        case DELETE_PROJECT_REQUEST:
            return {loading:true,...state}
        case DELETE_PROJECT_SUCCESS:
            return {loading:false, ...action.payload}
        case DELETE_PROJECT_FAIL:
            return {loading: false,error:action.payload}
        case DELETE_PROJECT_RESET:
            return {}
        default:
            return state
    }
}


export const bookConsultationReducer =(state={},action)=>{
    switch(action.type){
        case BOOK_CONSULTATION_REQUEST:
            return {loading:true,...state}
        case BOOK_CONSULTATION_SUCCESS:
            return {loading:false, ...action.payload}
        case BOOK_CONSULTATION_FAIL:
            return {loading: false,error:action.payload}
        case BOOK_CONSULTATION_RESET:
            return {}
        default:
            return state
    }
}


export const contactUsReducer =(state={},action)=>{
    switch(action.type){
        case CONTACT_US_REQUEST:
            return {loading:true,...state}
        case CONTACT_US_SUCCESS:
            return {loading:false, ...action.payload}
        case CONTACT_US_FAIL:
            return {loading: false,error:action.payload}
        default:
            return state
    }
}

export const tutorialReducer =(state={},action)=>{
    switch(action.type){
        case TUTORIALS_REQUEST:
            return {loading:true,...state}
        case TUTORIALS_SUCCESS:
            return {loading:false, tutorials:action.payload}
        case TUTORIALS_FAIL:
            return {loading: false,error:action.payload}
        default:
            return state
    }
}


export const tutorial_detailReducer =(state={},action)=>{
    switch(action.type){
        case TUTORIALS_DETAILS_REQUEST:
            return {loading:true,...state}
        case TUTORIALS_DETAILS_SUCCESS:
            return {loading:false, ...action.payload}
        case TUTORIALS_DETAILS_FAIL:
            return {loading: false,error:action.payload}
        default:
            return state
    }
}

export const certReducer =(state={},action)=>{
    switch(action.type){
        case VERIFY_N_VIEW_CERT_REQUEST:
            return {loading:true,...state}
        case VERIFY_N_VIEW_CERT_SUCCESS:
            return {loading:false, ...action.payload}
        case VERIFY_N_VIEW_CERT_FAIL:
            return {loading: false,error:action.payload}
        default:
            return state
    }
}

export const newsLetterReducer =(state={},action)=>{
    switch(action.type){
        case NEWS_LETTER_REQUEST:
            return {loading:true,...state}
        case NEWS_LETTER_SUCCESS:
            return {loading:false, ...action.payload}
        case NEWS_LETTER_FAIL:
            return {loading: false,error:action.payload}
        default:
            return state
    }
}


export const dashboardReducer =(state={},action)=>{
    switch(action.type){
        case DASHBOARD_DATA_REQUEST:
            return {loading:true,...state}
        case DASHBOARD_DATA_SUCCESS:
            return {loading:false, ...action.payload}
        case DASHBOARD_DATA_FAIL:
            return {loading: false,error:action.payload}
        default:
            return state
    }
}


export const userLoginReducer =(state={},action)=>{
    switch(action.type){
        case USER_LOGIN_REQUEST:
            return {loading:true}
        case USER_LOGIN_SUCCESS:
            return {loading:false, userInfo:action.payload}
        case USER_LOGIN_FAIL:
            return {loading: false,error:action.payload}
        case USER_LOGOUT:
            return {}
        case USER_PROFILE_RESET:
            return {}
        default:
            return state
    }
}


export const settingsReducer =(state={},action)=>{
    switch(action.type){
        case SETTINGS_REQUEST:
            return {loading:true}
        case SETTINGS_SUCCESS:
            return {loading:false, userInfo:action.payload}
        case SETTINGS_FAIL:
            return {loading: false,error:action.payload}
        case USER_LOGOUT:
            return {}
        case SETTINGS_RESET:
            return {}
        case USER_PROFILE_RESET:
            return {}
        default:
            return state
    }
}


export const passwordResetReducer =(state={},action)=>{
    switch(action.type){
        case PASSWORD_RESET_REQUEST:
            return {loading:true,...state}
        case PASSWORD_RESET_SUCCESS:
            return {loading:false, success:action.payload}
        case PASSWORD_RESET_FAIL:
            return {loading: false,error:action.payload}
        case USER_LOGOUT:
            return {}
        default:
            return state
    }
}


export const signUpReducer =(state={},action)=>{
    switch(action.type){
        case USER_REGISTER_REQUEST:
            return {loading:true}
        case USER_REGISTER_SUCCESS:
            return {loading:false,success:true, userInfo:action.payload}
        case USER_REGISTER_FAIL:
            return {loading: false,error:action.payload}
        case USER_LOGOUT:
            return {}
        case USER_PROFILE_RESET:
            return {}
        default:
            return state
    }
}


export const paymentReducer =(state={success:false},action)=>{
    switch(action.type){
        case PAYMENT_CHECK_REQUEST:
            return {loading:true}
        case PAYMENT_CHECK_SUCCESS:
            return {loading:false, success: action.payload}
        case PAYMENT_CHECK_FAIL:
            return {loading: false,error:action.payload}
        case PAYMENT_RESET:
            return {}
        default:
            return state
    }
}


export const passwordSetReducer =(state={},action)=>{
    switch(action.type){
        case PASSWORD_SET_REQUEST:
            return {loading:true,...state}
        case PASSWORD_SET_SUCCESS:
            return {loading:false, success:action.payload}
        case PASSWORD_SET_FAIL:
            return {loading: false,error:action.payload}
        case PASSWORD_RESET_RESET:
            return {}
        case USER_LOGOUT:
            return {}
        default:
            return state
    }
}


export const tokenRequestReducer =(state={},action)=>{
    switch(action.type){
        case RESET_TOKEN_REQUEST:
            return {loading:true,...state}
        case RESET_TOKEN_SUCCESS:
            return {loading:false, success:action.payload}
        case RESET_TOKEN_FAIL:
            return {loading: false,error:action.payload}
        case USER_LOGOUT:
            return {}
        default:
            return state
    }
}

