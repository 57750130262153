import React, { useEffect, useState } from 'react'
import DashboardNav from './DashboardNav'
import SideBar from './SideBar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import IsEmpty from './IsEmpty'
import Modal from 'react-bootstrap/Modal';
import ServicesDecode from './ServicesDecode'
import { PaystackButton } from 'react-paystack'
import { paymentAction } from './HomeActions'
import { PAYMENT_RESET } from './Constants'
import DateFormater from './DateFormater'
import Lengther from './Lengther'
import Message from './Message'

const OrderSummary = () => {
    const [paywith, setPaywith] = useState("paystack")
    const [smShow, setSmShow] = useState(false);
    const [delVal, setDelVal] = useState({});
    
    const [showValue, setShowValue] = useState(true);
    
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useNavigate()

    const redirect = location.search ? location.search.split("=")[1]: "/login"

    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin

    const paymentVerification = useSelector(state=> state.paymentVerification)
    const {error,success} = paymentVerification


    useEffect(()=>{
        if (!userInfo){
            history(redirect)
        }
        setShowSuccess(false)
    },[history,userInfo,redirect,localStores])

    const [localStores, setLocalStores] = useState(userInfo ? (localStorage.getItem(`Cart${userInfo.id}`) ? JSON.parse(localStorage.getItem(`Cart${userInfo.id}`)) : []) : []);
    
    const HandlePaymentSelection = (x)=>{
        let cart = localStorage.getItem(`Cart${userInfo.id}`) ? JSON.parse(localStorage.getItem(`Cart${userInfo.id}`)) : []

        let cartfilt = cart.filter(m =>m.id === x.id)
        if (IsEmpty(cart)){
            localStorage.setItem(`Cart${userInfo.id}`, JSON.stringify([x]))
            setLocalStores([x])
        }else if(!IsEmpty(cartfilt)){
            let cartfilter = cart.filter(m =>m.id !== x.id)
            localStorage.setItem(`Cart${userInfo.id}`, JSON.stringify(cartfilter))
            setLocalStores(cartfilter)
        }else{
            cart.unshift(x)
            localStorage.setItem(`Cart${userInfo.id}`, JSON.stringify(cart))
            setLocalStores(cart)
        }
        setShowValue(false)
        setShowSuccess(false)
    }
    
    const calcPrices = !IsEmpty(localStores) ? localStores.reduce((x,item)=>x + parseInt((item.price).trim()),0).toFixed(2) : null
    const itemsPrices = (!IsEmpty(localStores) && localStores.length >= 5) ? calcPrices - (calcPrices * 0.1 ) : (!IsEmpty(localStores) && localStores.length >= 3) ? calcPrices - (calcPrices * 0.07 ) : (!IsEmpty(localStores) && localStores.length >= 2) ? calcPrices - (calcPrices * 0.05 ) : calcPrices
    const isDiscount = (!IsEmpty(localStores) && localStores.length > 1) ? true : false
    

    const componentProps = {
        email: userInfo ? userInfo.email : "",
        amount: itemsPrices ? itemsPrices * 100 : 0,
        metadata: {
          name:userInfo ? userInfo.first_name : "",
        },
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
        text: "Pay now",
        reference : (new Date()).getTime().toString(),
        onSuccess: (my_response) => dispatch(paymentAction({...my_response,amount:itemsPrices,currency : "NGN",datas: localStores ? localStores : []})),
    }

    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(()=>{
        if (success && success==="success"){
            localStores.map(x=>HandlePaymentSelection(x))
            setShowSuccess(true)
            dispatch({
                type: PAYMENT_RESET
            })
        }
    },[success])
    
    return (
        <div>
            <div>
                <SideBar />
            </div>
            <div className='pt_1'></div>
            <div className='mainContent shadow'>
                <DashboardNav />
                <div className='mainContentDashboardsubs'>
                    <div className="DashboardTicketsContainer">
                        <div>
                            <div className="font_24 bold8">
                                Order summary
                            </div>
                            {error && <Message variant={"danger"}>An errror occurred</Message>}
                            <div className="pt_3">
                                {IsEmpty(localStores) && 
                                    <div className='requestOuterContainer'>
                                        <div className="text-muted">
                                            { !showSuccess ? "Your order is empty" : "Payment was successful. Your dashboard will be updated." }
                                        </div>
                                    </div>
                                }
                                {localStores && !IsEmpty(localStores) && 
                                    <div className='orderSummarY'>
                                        {localStores.map((x)=>(
                                            <div className="requestOuterContainer" key={x.id}>
                                                <div className="requestInnerContainer shadow_sm">
                                                    <div className="reqGriders">
                                                        <div className='padmeReq'>
                                                            <div className="font_14 bold8">
                                                                <ServicesDecode KEYS={x.request.service} />
                                                            </div>
                                                            <div className="font_12 bold6">
                                                                <Lengther amount={76}>
                                                                    {x.request.subject}
                                                                </Lengther>
                                                            </div>
                                                            <div className="font_12 text-muted">
                                                                <DateFormater date={x.date} />
                                                            </div>
                                                        </div>
                                                        <div className='flex alignCenter'>
                                                            <div className={x.reference === "Completed" ? "center w-100 text-success bold6 primax" : x.reference === "Ongoing" ? "center w-100 bold6 primax" : "center w-100 text-muted bold6 primax"}>
                                                                {x.reference}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='selectedReqContainer flex'>
                                                        

                                                        <div className="left_auto xcd">
                                                            <img className='pointer' src="https://img.icons8.com/ios-filled/20/6C757D/delete--v1.png"
                                                                onClick={()=>{
                                                                    setSmShow(true)
                                                                    setDelVal(x)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {itemsPrices &&
                                            <div className='priceContainer_Displayer'>
                                                <span className="flex xc text-success">
                                                    {process.env.REACT_APP_CURRENCY_SYMBOL}{itemsPrices}
                                                </span>
                                                <span className='flex xc font_12 text-muted'>
                                                    {isDiscount && "Discount applied"}
                                                </span>
                                                <PaystackButton className="paystack-button left_auto btn-sm btn bg-primary text-white" {...componentProps} />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div></div>
                    </div>

                </div>
            </div>
            <Modal
                size="sm"
                show={smShow}
                onHide={() => {
                    setSmShow(false)
                    setDelVal({})
                    setShowValue(true)
                }}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <span className="text-danger font_14 bold6">
                        Are sure you want to remove this order?
                    </span>
                </Modal.Header>
                <Modal.Body>
                    {showValue &&
                        <button className='btn btn-sm bg-danger w-100 center text-white'
                            onClick={()=>HandlePaymentSelection(delVal)}
                        >
                            Delete
                        </button>
                    }
                    {!showValue && 
                        <span className="text-success font_12">
                            Success!
                        </span>
                    }

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default OrderSummary
