import React, { useState } from 'react';
import NavBar from './NavBar'
import Footer from './Footer'
import BookConsultation from './BookConsultation';

const Packages = () => {
    const [lgShow, setLgShow] = useState(true);
    return (
        <div>
            <NavBar />
            <div className="bg_Services relative">
                <div className="thirdStandardWidth ">
                    <div className="HomeAbout xdr">
                        <div className="red center bold6 font_12">
                            Grey Valor
                        </div>
                        <div className="Header2 center">
                            Available Packages
                        </div>
                        <div className="pt_3">
                            We have designed three packages at friendly prices to ensure our customers get the best offer based on their needs. 
                        </div>
                        <div className="pt_2 font_14">
                            <span className="font_14 bold6 Basicx">
                                Basic:
                            </span>
                            Request two digital services and get a 5% discount.
                        </div>
                        <div className="pt_2 font_14">
                            <span className="font_14 bold6 Standardx">
                                Standard:
                            </span>
                            Request three services or more and get a 6-7% discount.
                        </div>
                        <div className="pt_2 font_14">
                            <span className="font_14 bold6 Premiumx">
                                Premium:
                            </span>
                            Request five services or more and get a 9-10% discount. What is more? You also get to receive weekly feedback on the progress of your request. 
                        </div>
                        <div className="borderBottoms"></div>
                        
                    </div>
                    <img src="/Backgrounds/rect.svg" alt="." className='square9' />
                    <img src="/Backgrounds/startLight.svg" alt="." className='square10' />
                    <img src="/Backgrounds/square5.svg" alt="." className='square11' />
                    <img src="/Backgrounds/square6.svg" alt="." className='square12' />
                </div>
                <div className="secondStandardWidth">
                    <div className="pt_2"></div>
                    <div className="PackagesContainer px_3">
                        <div className="outerPackageMini relative">
                            <div className="PackageMini rbk">
                                <div className="essentialContainer">
                                    BASIC
                                </div>
                                <div className="innerSiderContent">
                                    <div className="flex">
                                        <div className="flex centerx">
                                            <div className='centery'>
                                                <div className="pt_2 bold5">
                                                    %
                                                </div>
                                            </div>
                                            <div className='priceContainer'>
                                                4-5
                                            </div>
                                            <div>
                                                <div className='pt_1'>
                                                    <div className='text-muted font_12 bold6'>
                                                        Discount on
                                                    </div>
                                                    <div className='font_11'>
                                                        2 or more services
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt_2'>
                                        <div className='pb_1'>
                                            <samp>**</samp>
                                            <span className="pl_1 font_12 bold6">
                                                Example:
                                            </span>
                                        </div>
                                        <div className='pb_1'>
                                            <img src="/Backgrounds/button.svg" alt="." />
                                            <span className="pl_1 font_14 bold6">
                                                Mobile Development
                                            </span>
                                        </div>
                                        <div className='pb_1'>
                                            <img src="/Backgrounds/button.svg" alt="." />
                                            <span className="pl_1 font_14 bold6">
                                                Web development
                                            </span>
                                        </div>
                                        <div className="pt_2">
                                            <span onClick={() => {
                                                setLgShow(!lgShow)
                                            }}
                                            className="packagesPlanBtn pointer"
                                            >
                                                Get started
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="PackageMini las">
                            <div className="essentialContainer">
                                PREMIUM
                            </div>
                            <div className="innerSiderContent">
                                <div className="flex">
                                    <div className="flex centerx">
                                        <div className='centery'>
                                            <div className="pt_2 bold5">
                                                %
                                            </div>
                                        </div>
                                        <div className='priceContainer'>
                                            9-10
                                        </div>
                                        <div>
                                            <div className='pt_1'>
                                                <div className='text-muted font_12 bold6'>
                                                    Discount on
                                                </div>
                                                <div className='font_11'>
                                                    5 or more services
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt_2'>
                                    <div className='pb_1'>
                                        <samp>**</samp>
                                        <span className="pl_1 font_14 bold6">
                                            Example:
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            Brand Identity design
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            Web development
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            Mobile Development
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            UI/UX Design
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            Graphic Design
                                        </span>
                                    </div>
                                    <div className="pt_2">
                                        <span onClick={() => {
                                            setLgShow(!lgShow)
                                        }}
                                        className="packagesPlanBtn pointer"
                                        >
                                            Get started
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="outerPackageMini relative">
                            <div className="PackageMini lbk">
                                <div className="essentialContainer">
                                    STANDARD
                                </div>
                                <div className="innerSiderContent">
                                    <div className="flex">
                                        <div className="flex centerx">
                                            <div className='centery'>
                                                <div className="pt_2 bold5">
                                                    %
                                                </div>
                                            </div>
                                            <div className='priceContainer'>
                                                6-7
                                            </div>
                                            <div>
                                                <div className='pt_1'>
                                                    <div className='text-muted font_12 bold6'>
                                                        Discount on
                                                    </div>
                                                    <div className='font_11'>
                                                        3 or more services
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt_2'>
                                        <div className='pb_1'>
                                            <samp>**</samp>
                                            <span className="pl_1 font_12 bold6">
                                                Example:
                                            </span>
                                        </div>
                                        <div className='pb_1'>
                                            <img src="/Backgrounds/button.svg" alt="." />
                                            <span className="pl_1 font_14 bold6">
                                                Brand Identity design
                                            </span>
                                        </div>
                                        <div className='pb_1'>
                                            <img src="/Backgrounds/button.svg" alt="." />
                                            <span className="pl_1 font_14 bold6">
                                                Web development
                                            </span>
                                        </div>
                                        <div className='pb_1'>
                                            <img src="/Backgrounds/button.svg" alt="." />
                                            <span className="pl_1 font_14 bold6">
                                                Mobile development
                                            </span>
                                        </div>
                                        <div className="pt_2">
                                            <span onClick={() => {
                                                setLgShow(!lgShow)
                                            }}
                                            className="packagesPlanBtn pointer"
                                            >
                                                Get started
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="HomeAbout xdr"></div>
            </div>
            <BookConsultation action={lgShow} />
            <Footer />
        </div>
    )
}

export default Packages