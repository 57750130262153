export const COMMENTS_LIST_REQUEST = "COMMENTS_LIST_REQUEST"
export const COMMENTS_LIST_SUCCESS = "COMMENTS_LIST_SUCCESS"
export const COMMENTS_LIST_FAIL = "COMMENTS_LIST_FAIL"

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"

export const USER_LOGOUT = "USER_LOGOUT"

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"

export const BOOK_CONSULTATION_REQUEST = "BOOK_CONSULTATION_REQUEST"
export const BOOK_CONSULTATION_SUCCESS = "BOOK_CONSULTATION_SUCCESS"
export const BOOK_CONSULTATION_FAIL = "BOOK_CONSULTATION_FAIL"
export const BOOK_CONSULTATION_RESET = "BOOK_CONSULTATION_RESET"

export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST"
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS"
export const CONTACT_US_FAIL = "CONTACT_US_FAIL"

export const NEWS_LETTER_REQUEST = "NEWS_LETTER_REQUEST"
export const NEWS_LETTER_SUCCESS = "NEWS_LETTER_SUCCESS"
export const NEWS_LETTER_FAIL = "NEWS_LETTER_FAIL"

export const DASHBOARD_DATA_REQUEST = "DASHBOARD_DATA_REQUEST"
export const DASHBOARD_DATA_SUCCESS = "DASHBOARD_DATA_SUCCESS"
export const DASHBOARD_DATA_FAIL = "DASHBOARD_DATA_FAIL"

export const USER_PROFILE_RESET = "USER_PROFILE_RESET"

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"
export const DELETE_PROJECT_RESET = "DELETE_PROJECT_RESET"

export const PAYMENT_CHECK_REQUEST = "PAYMENT_CHECK_REQUEST"
export const PAYMENT_CHECK_SUCCESS = "PAYMENT_CHECK_SUCCESS"
export const PAYMENT_CHECK_FAIL = "PAYMENT_CHECK_FAIL"
export const PAYMENT_RESET = "PAYMENT_RESET"

export const SETTINGS_REQUEST = "SETTINGS_REQUEST"
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS"
export const SETTINGS_FAIL = "SETTINGS_FAIL"
export const SETTINGS_RESET = "SETTINGS_RESET"

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST"
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS"
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL"
export const PASSWORD_RESET_RESET = "PASSWORD_RESET_RESET"

export const PASSWORD_SET_REQUEST = "PASSWORD_SET_REQUEST"
export const PASSWORD_SET_SUCCESS = "PASSWORD_SET_SUCCESS"
export const PASSWORD_SET_FAIL = "PASSWORD_SET_FAIL"

export const RESET_TOKEN_REQUEST = "RESET_TOKEN_REQUEST"
export const RESET_TOKEN_SUCCESS = "RESET_TOKEN_SUCCESS"
export const RESET_TOKEN_FAIL = "RESET_TOKEN_FAIL"

export const TUTORIALS_REQUEST = "TUTORIALS_REQUEST"
export const TUTORIALS_SUCCESS = "TUTORIALS_SUCCESS"
export const TUTORIALS_FAIL = "TUTORIALS_FAIL"

export const TUTORIALS_DETAILS_REQUEST = "TUTORIALS_DETAILS_REQUEST"
export const TUTORIALS_DETAILS_SUCCESS = "TUTORIALS_DETAILS_SUCCESS"
export const TUTORIALS_DETAILS_FAIL = "TUTORIALS_DETAILS_FAIL"

export const ADD_STUDENT_REQUEST = "ADD_STUDENT_REQUEST"
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS"
export const ADD_STUDENT_FAIL = "ADD_STUDENT_FAIL"
export const ADD_STUDENT_RESET = "ADD_STUDENT_RESET"

export const VERIFY_N_VIEW_CERT_REQUEST = "VERIFY_N_VIEW_CERT_REQUEST"
export const VERIFY_N_VIEW_CERT_SUCCESS = "VERIFY_N_VIEW_CERT_SUCCESS"
export const VERIFY_N_VIEW_CERT_FAIL = "VERIFY_N_VIEW_CERT_FAIL"

