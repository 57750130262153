import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import BookConsultation from './BookConsultation'
import Footer from './Footer'
import NavBar from './NavBar'
import { homeLists } from './HomeActions';

import { useDispatch, useSelector } from 'react-redux'
import Loader from './Loader'

const Home = () => {
    const [lgShow, setLgShow] = useState(true);
    const [servece, setServece] = useState("");

    const history = useNavigate()
    
    const dispatch = useDispatch()
    const homeList = useSelector(state =>state.homeList)
    const {loading,messages} = homeList

    useEffect(()=>{
        dispatch(homeLists())
    },[dispatch,history])
    

  return (
    <div>
        <NavBar />
        <BookConsultation action={lgShow} service={servece} />
        
        
        <div className="LandingPage ttp">
            <div className="relative xf">
                <div className="secondStandardWidth h_100">
                    <div className="centerMe">
                        <div className="containerLander">
                            <div className="HeaderOne">
                                Think-Make-Solve
                            </div>
                            <div className="pt_1">
                                <div className="justify_me pr_2 xrc bold5 text_shadow">
                                    Grey Valour is a global digital hub that uses innovative 
                                    skills and digital
                                    technologies to provide digital solutions to businesses.
                                </div>
                            </div>
                            <div className="pt_2">
                                <button className='GeneralBookingBtn'
                                    onClick={() => {
                                        setLgShow(!lgShow)
                                    }}
                                >
                                    Request Services
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="/Backgrounds/sqare1.svg" alt="." className='square1' />
                <img src="/Backgrounds/square2.svg" alt="." className='square2' />
                <img src="/Backgrounds/square3.svg" alt="." className='square3' />
                <img src="/Images/Hero Image.png" alt="GreyValor" className='LandingImg xdf' />
            </div>
            <div className="thirdStandardWidth relative">
                <div className="HomeAbout">
                    <div className="Header2 center">
                        About Us
                    </div>
                    <div className="pt_3 justify_me">
                        <span className='bold7 pr_1'>FOR FREELANCERS :</span> We are a team 
                        of technology experts with the mindset of creating digital technology 
                        solutions for companies and establishments. We aim to build an 
                        open-source community that enables freelancers, tech-savvy experts, 
                        and business owners to collaborate and share ideas freely.
                        <div className='pt_2'>     
                            <span className='bold7 pr_1'>FOR STARTUPS :</span> We are a team of young creatives
                             passionate about creating digital support to promote startups and 
                             companies. Our services will be tailored to capturing the needs 
                             of our clients and telling their unique story.
                        </div>
                    </div>
                    <div className="VisionContainer">
                        <div className='visionCenter'>
                            <div className="center">
                                <img src="/Backgrounds/svg1.svg" alt="." />
                            </div>
                            <div className="center py_15 font_15 bold6">
                                Vision
                            </div>
                            <div className="center font_14 justify_me">
                                To be a leading global tech hub that provides all-round 
                                tech solutions/resources for companies and establishments.
                            </div>
                        </div>
                        <div className='visionCenter'>
                            <div className="center">
                                <img src="/Backgrounds/svg2.svg" alt="." />
                            </div>
                            <div className="center py_15 font_15 bold6">
                                Mission
                            </div>
                            <div className="center font_14 justify_me">
                                Apply technology to reconcile the gap between
                                 your business pain point and your desired solution.
                            </div>
                        </div>
                        <div className='visionCenter'>
                            <div className="center">
                                <img src="/Backgrounds/svg3.svg" alt="." />
                            </div>
                            <div className="center py_15 font_15 bold6">
                                Goal
                            </div>
                            <div className="center font_14 justify_me">
                                With technology as our watchword, we aim to deliver
                                excellent and customer-centred
                                solutions to the utmost satisfaction of our clients.
                            </div>
                        </div>
                    </div>
                </div>
                <img src="/Backgrounds/square4.svg" alt="." className='square4' />
            </div>
        </div>
        
        <div className="bg_Update relative">
            <div className="secondStandardWidth">
                <div className="TheInfo">
                    <div className="pr_3">
                        <div className="pt_4 Header3 white">
                            Why should you choose Grey Valour?
                        </div>
                        <div className="white pt_2 justify_me">
                        Grey Valour will showcase the beauty of your brand and project, 
                        and tell your unique story in a manner that communicates with your 
                        customers, while providing 
                        professional digital solutions that are affordable and reliable.
                        </div>
                        <div className="pt_2">
                            <button className='GeneralBookingBtn xtra higher'
                                onClick={() => {
                                    setLgShow(!lgShow)
                                }}
                            >
                                Request Services
                            </button>
                        </div>
                    </div>
                    <div>
                        <img src="/Images/Extra section.png" alt="Happy People" />
                    </div>
                </div>
            </div>
            <img src="/Backgrounds/square3.svg" alt="." className='square8' />
            <img src="/Backgrounds/square3.svg" alt="." className='square6' />
            <img src="/Backgrounds/star.svg" alt="." className='square7' />

        </div>
        <div className="bg_Services relative">
            <div className="thirdStandardWidth ">
                <div className="HomeAbout xdr">
                    <div className="red center bold6 font_12">
                        Services
                    </div>
                    <div className="Header2 center">
                        Our Services
                    </div>
                    <div className="pt_3 justify_me">
                    Grey Valour offers a spectrum of services to promote 
                    excellence and growth in your company, business, or establishment. 
                    We ensure it has an engaging digital presence with the platform 
                    to share 
                    innovative ideas with business owners via our online community.
                        
                    </div>
                    <div className="borderBottoms"></div>
                    
                </div>
                <img src="/Backgrounds/rect.svg" alt="." className='square9' />
                <img src="/Backgrounds/startLight.svg" alt="." className='square10' />
                <img src="/Backgrounds/square5.svg" alt="." className='square11' />
                <img src="/Backgrounds/square6.svg" alt="." className='square12' />
            </div>
            <div className="secondStandardWidth">
                <div className="ServicesContainers px_5">
                    <div className="colsM">
                        <Link to={"/design"} className='text-dark'>
                            <div className='center'>
                                <img src="/Backgrounds/svg4.svg" alt="" />
                            </div>
                            <div className="py_2 center bold6 font_18">
                                Design
                            </div>
                            <div className="font_13 center">
                                Our design service help drive excellence by giving
                                your business the best aesthetics in the digital world.
                            </div>
                            <div className="pt_1 center">
                                <span className="bookserViceOption pointer">
                                    Book Service
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="colsM"
                        onClick={() => {
                            setLgShow(!lgShow)
                            setServece("wd")
                        }}
                    >
                        <div className='center'>
                            <img src="/Backgrounds/svg5.svg" alt="." />
                        </div>
                        <div className="py_2 center bold6 font_18">
                            Web Development
                        </div>
                        <div className="font_13 center">
                            We develop user-friendly websites to help companies 
                            interact with their target audience. 
                        </div>
                        <div className="pt_1 center">
                            <span className='bookserViceOption bg-none'>
                                Book Service
                            </span>
                        </div>
                    </div>
                    <div className="colsM"
                        onClick={() => {
                            setLgShow(!lgShow)
                            setServece("ma")
                        }}
                    >
                        <div className='center'>
                            <img src="/Backgrounds/Path1.svg" alt="." />
                        </div>
                        <div className="py_2 center bold6 font_18">
                            Mobile Development
                        </div>
                        <div className="font_13 center">
                            We develop mobile applications for Android and 
                            IOS devices that will improve customer interaction and experience. 
                        </div>
                        <div className="pt_1 center">
                            <span className='bookserViceOption bg-none'>
                                Book Service
                            </span>
                        </div>
                    </div>
                    <div className="colsM">
                        <Link to={"/digital-marketing"} className='text-dark'>
                            <div className='center'>
                                <img src="/Backgrounds/svg6.svg" alt="." />
                            </div>
                            <div className="py_2 center bold6 font_18">
                                Digital Marketing
                            </div>
                            <div className="font_13 center">
                                We give your business an engaging digital presence 
                                via our different digital marketing tools. 
                            </div>
                            <div className="pt_1 center">
                                <span className="bookserViceOption">
                                    Book Service
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="colsM">
                        <Link to={"/mentorship/tutorship"} className='text-dark'>
                            <div className='center'>
                                <img src="/Backgrounds/Path2.svg" alt="." />
                            </div>
                            <div className="py_2 center bold6 font_18">
                                Mentorship/Tutorship
                            </div>
                            <div className="font_13 center">
                                We help tech enthusiasts get equipped with the right skills that will 
                                enable them adapt quickly to the dynamic tech ecosystem.
                            </div>
                            <div className="pt_1 center">
                                <span className="bookserViceOption">
                                    Book Service
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="colsM">
                        <div className='center'>
                            <img src="/Backgrounds/Path3.svg" alt="." />
                        </div>
                        <div className="py_2 center bold6 font_18">
                            Embedded System
                        </div>
                        <div className="font_13 center">
                            We help automate your business process by providing smart-embedded computer technologies to aid your system interaction. 
                        </div>
                        <div className="pt_1 center">
                            <span className='text-muted font_12 bold6 pointer'>
                                Coming soon
                            </span>
                        </div>
                    </div>
                    <div className="colsM">
                        <div className='center'>
                            <img src="/Backgrounds/Path4.svg" alt="." />
                        </div>
                        <div className="py_2 center bold6 font_18">
                            Online Community
                        </div>
                        <div className="font_13 center">
                            We have an interactive platform where tech enthusiasts and business owners can share ideas, innovations and make progress.
                        </div>
                        <div className="pt_1 center">
                            <span className='text-muted font_12 bold6 pointer'>
                                Coming soon
                            </span>
                        </div>
                    </div>
                </div>
                <div className="borderBottomss"></div>

            </div>
            <div className="HomeAbout xdr"></div>
        </div>
        <div className='bg_Brownish relative'>
            <div className="thirdStandardWidth relative">
                <div>
                    <div className="red center bold6 font_12">
                        Ready
                    </div>
                    <div className="Header2 center">
                        Packages
                    </div>
                    <div className="pt_3 justify_me">
                        We have three packages: basic, premium and standard, which highly depends on 
                        your preferences. Also, note that our packages come with discounts.
                    </div>
                    <div className="borderBottoms"></div>
                    
                </div>
                
            </div>
            <div className="secondStandardWidth">
                <div className="pt_2"></div>
                <div className="PackagesContainer px_3">
                    <div className="outerPackageMini relative">
                        <div className="PackageMini rbk">
                            <div className="essentialContainer">
                                BASIC
                            </div>
                            <div className="innerSiderContent">
                                <div className="flex">
                                    <div className="flex centerx">
                                        <div className='centery'>
                                            <div className="pt_2 bold5">
                                                %
                                            </div>
                                        </div>
                                        <div className='priceContainer'>
                                            4-5
                                        </div>
                                        <div>
                                            <div className='pt_1'>
                                                <div className='text-muted font_12 bold6'>
                                                    Discount on
                                                </div>
                                                <div className='font_11'>
                                                    2 or more services
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt_2'>
                                    <div className='pb_1'>
                                        <samp>**</samp>
                                        <span className="pl_1 font_12 bold6">
                                            Example:
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            Mobile Development
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            Web development
                                        </span>
                                    </div>
                                    <div className="pt_2">
                                        <span onClick={() => {
                                            setLgShow(!lgShow)
                                        }}
                                        className="packagesPlanBtn pointer"
                                        >
                                            Get started
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="PackageMini las">
                        <div className="essentialContainer">
                            PREMIUM
                        </div>
                        <div className="innerSiderContent">
                            <div className="flex">
                                <div className="flex centerx">
                                    <div className='centery'>
                                        <div className="pt_2 bold5">
                                            %
                                        </div>
                                    </div>
                                    <div className='priceContainer'>
                                        9-10
                                    </div>
                                    <div>
                                        <div className='pt_1'>
                                            <div className='text-muted font_12 bold6'>
                                                Discount on
                                            </div>
                                            <div className='font_11'>
                                                5 or more services
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pt_2'>
                                <div className='pb_1'>
                                    <samp>**</samp>
                                    <span className="pl_1 font_14 bold6">
                                        Example:
                                    </span>
                                </div>
                                <div className='pb_1'>
                                    <img src="/Backgrounds/button.svg" alt="." />
                                    <span className="pl_1 font_14 bold6">
                                        Brand Identity design
                                    </span>
                                </div>
                                <div className='pb_1'>
                                    <img src="/Backgrounds/button.svg" alt="." />
                                    <span className="pl_1 font_14 bold6">
                                        Web development
                                    </span>
                                </div>
                                <div className='pb_1'>
                                    <img src="/Backgrounds/button.svg" alt="." />
                                    <span className="pl_1 font_14 bold6">
                                        Mobile Development
                                    </span>
                                </div>
                                <div className='pb_1'>
                                    <img src="/Backgrounds/button.svg" alt="." />
                                    <span className="pl_1 font_14 bold6">
                                        UI/UX Design
                                    </span>
                                </div>
                                <div className='pb_1'>
                                    <img src="/Backgrounds/button.svg" alt="." />
                                    <span className="pl_1 font_14 bold6">
                                        Graphic Design
                                    </span>
                                </div>
                                <div className="pt_2">
                                    <span onClick={() => {
                                        setLgShow(!lgShow)
                                    }}
                                    className="packagesPlanBtn pointer"
                                    >
                                        Get started
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="outerPackageMini relative">
                        <div className="PackageMini lbk">
                            <div className="essentialContainer">
                                STANDARD
                            </div>
                            <div className="innerSiderContent">
                                <div className="flex">
                                    <div className="flex centerx">
                                        <div className='centery'>
                                            <div className="pt_2 bold5">
                                                %
                                            </div>
                                        </div>
                                        <div className='priceContainer'>
                                            6-7
                                        </div>
                                        <div>
                                            <div className='pt_1'>
                                                <div className='text-muted font_12 bold6'>
                                                    Discount on
                                                </div>
                                                <div className='font_11'>
                                                    3 or more services
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt_2'>
                                    <div className='pb_1'>
                                        <samp>**</samp>
                                        <span className="pl_1 font_12 bold6">
                                            Example:
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            Brand Identity design
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            Web development
                                        </span>
                                    </div>
                                    <div className='pb_1'>
                                        <img src="/Backgrounds/button.svg" alt="." />
                                        <span className="pl_1 font_14 bold6">
                                            Mobile development
                                        </span>
                                    </div>
                                    <div className="pt_2">
                                        <span onClick={() => {
                                            setLgShow(!lgShow)
                                        }}
                                        className="packagesPlanBtn pointer"
                                        >
                                            Get started
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="secondStandardWidth">
                <div className="HomeAbout">
                    <div className="red center bold6 font_12">
                        Clients
                    </div>
                    <div className="Header2 center">
                        Feedbacks
                    </div>
                    <div className="borderBottomss"></div>
                    
                    
                    {loading ?
                        <Loader />
                    :
                        (
                            <div className="feedbackContainer">
                                {messages ? messages.map((x)=>(
                                    <div className="FeedbackContent" key={x.id}>
                                        <div className="font_14 justify_me">
                                            {x.text}
                                        </div>
                                        <div className="pt_2 bold7 font_15">
                                            {x.name}
                                        </div>
                                        <div className="pt_05">
                                            <img src="/Backgrounds/stars.svg" alt="" />
                                        </div>
                                    </div>
                                ))
                            :
                                ""
                            }
                            </div>
                        )
                    }
                </div>
                
            </div>
            <img src="/Backgrounds/square5.svg" alt="." className='square13' />
            <img src="/Backgrounds/square5.svg" alt="." className='square15' />
            <img src="/Backgrounds/square5.svg" alt="." className='square14' />
            <img src="/Backgrounds/littleStar.svg" alt="." className='square16' />

        </div>
        <div className="bg_Brownish">
            <div className="secondStandardWidth">
                <div className="HomeAbout smPadi">
                    <div className="red center bold6 font_12">
                        Most
                    </div>
                    <div className="Header2 center">
                        Recent Projects
                    </div>
                    <div className="borderBottomss"></div>
                        
                    <div className='pb_7'>
                        <a href="https://www.ustuda.com" target='_blank'>
                            <img src="/PreviousWorks/ustuda.png" alt="Ustuda" />
                        </a>
                        <div className='pt_2 justify_me'>
                            <span className="bold7 pr_1">
                                Ustuda :
                            </span>
                            An online learning platform that offers courses for purchase, 
                            allowing you to learn at your own pace while also participating 
                            in live classes.
                        </div>
                    </div>
                    <div className='pb_7'>
                        <a href="https://www.kidsmulticulturalworld.org" target='_blank'>
                            <img src="/PreviousWorks/kidsmulticulturalworld.png" alt="Ustuda" />
                        </a>
                        <div className='pt_2 justify_me'>
                            <span className="bold7 pr_1">
                                Kids Multicultural World :
                            </span>
                            Elevate children's talents to the next level with fashion shows, 
                            magazine features,dedicated modeling, and acting sections for  kids 
                            aged 0 to 17. Enroll today and establish your profile.
                        </div>
                    </div>
                    {/* <div className='pb_7'>
                        <img src="/PreviousWorks/autoxona.png" alt="Ustuda" />
                        <div className='pt_2 justify_me'>
                            <span className="bold7 pr_1">
                                Autoxona :
                            </span>
                            THE NUMBER ONE AUTOMOTIVE ONLINE MARKETPLACE
                            FOR EVERYTHING AUTO IN AFRICA
                        </div>
                    </div>
                    <div className='pb_7'>
                        <img src="/PreviousWorks/autoxonaMvp.png" alt="Ustuda" />
                        <div className='pt_2'>
                            <span className="bold7 pr_1 justify_me">
                                Autoxona MVP Design :
                            </span>
                            THE NUMBER ONE AUTOMOTIVE ONLINE MARKETPLACE
                            FOR EVERYTHING AUTO IN AFRICA
                        </div>
                    </div> */}
                    {/* <div>
                        <Link to={"/recent-projects"} className='bold6'>
                            Veiw all
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>
        <div>
            <img src="/Images/Frame 16.png" alt="" />
        </div>
        
        <Footer />
    </div>
  )
}

export default Home