import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { USER_LOGOUT } from './Constants';
import { passwordResetAction } from './HomeActions';
import Loader2 from './Loader2';
import Message from './Message';
import NavBar from './NavBar';


const ForgotPassword = () => {
    const [errorMsg,setErrorMsg] = useState()
    const [email,setEmail]= useState("")
    const dispatch = useDispatch()
    const history = useNavigate()
    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} =userLogin

    const passwordReset = useSelector(state => state.passwordReset)
    const {error,loading,success} =passwordReset

    useEffect(()=>{
        if(userInfo){
            history("/home")
        }
        if(success && success.success === "success"){
            dispatch({type: USER_LOGOUT})
            history("/reset-password/request-sent")
        }
    },[history,userInfo,success,dispatch])
    const submitHandler = (e)=>{
        e.preventDefault()
        setErrorMsg("")
        if(!email){
            setErrorMsg("Email field cannot be empty")
        }else{
            dispatch(passwordResetAction(email))
        }
    }
    return (
        <div>
            <NavBar />
        
            <div className='loginFormBg'>
                <div className="paddForm">
                    <div className="loginContainerBox3">
                        <div className="center bold4 font_24">
                            Forgot password
                        </div>
                        <div className="center text-dark pb_1 font_14">
                            Please enter your email to reset your password.
                        </div>
                        {errorMsg ? <Message variant={"danger"}>{errorMsg}</Message> : error ? <Message variant={"danger"} children={"No account with such email."}></Message> : ""}
                        <input type="text" placeholder='Input Email' 
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                        <div>
                            <button onClick={submitHandler}>
                                {loading ? <Loader2 variant={"light"} /> : "Submit"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword