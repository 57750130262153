import React from 'react'

const PaySymbol = ({value}) => {
    const money = {
        "₦" : "NGN",
        "$" : "USD"
    }
  return money[value]
}

export default PaySymbol