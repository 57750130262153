import React, { useEffect } from 'react'
import NavBar from './NavBar'
import Footer from './Footer'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { tutorialAction } from './HomeActions';
import Loader from './Loader';

const LiveTutorial = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const tutorial = useSelector(state=> state.tutorial)
    const {error, loading, tutorials} = tutorial

    useEffect(()=>{
        dispatch(tutorialAction())
    },[])

  return (
    <div>
        <NavBar />
        <div className="secondStandardWidth">
            <div className="HomeAbout">
                <div className="digMarktContainer">
                    <div>
                        <div className='font_38 bold7'>
                            Current and 
                        </div>
                        <div className="font_38 bold7 headerColor">
                            Upcoming Events
                        </div>
                        <div className="pt_1 text-muted font_14">
                            Discover Our Latest and Upcoming Courses: Enrich Your 
                            Knowledge and Expand Your Horizons
                        </div>
                    </div>
                    <div>
                        <div className='digitsContent'>
                            {loading && <Loader />}
                            {(tutorials && tutorials.length > 0 && !loading) ? tutorials.map(x=>(
                                    <div className='DigitList ' key={x.id}>
                                        <div className="text-warning bold7 font_14">
                                            {x.is_current ? "Current event" : "Upcoming event"} <span className='font_12 text-success'>({x.amount ? `${process.env.REACT_APP_CURRENCY_SYMBOL} ${(x.amount).toLocaleString("en-US")}` : "Free"})</span>
                                        </div>
                                        <div className="font_20 bold8">
                                            {x.title}
                                        </div>
                                        <div className="pt_1 font_14">
                                            {x.brief_description}
                                        </div>
                                        <div className="pt_1">
                                            <span className="bold7 font_14 text-primary pointer"
                                                onClick={() =>{
                                                    history(`/tutorial-detail/${x.id}`)
                                                }}
                                            >
                                                View details
                                            </span>
                                        </div>
                                    </div>
                                ))
                            :
                                <div className='DigitList'>
                                    <span className='text-danger bold6'>
                                        No tutorial available
                                    </span>
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <Footer />
    </div>
  )
}

export default LiveTutorial