import React from 'react'
import { Link } from 'react-router-dom'

const Logo = ({nav}) => {
  return (
    <div>
        {nav ? 
            <div className='logoSize_nav'>
                <Link to={"/"}>
                    <img src="/Logo/GV.png" alt="." />
                    <span className='center2 greyvalour greyvalourFont31'>
                        Grey Valour
                    </span>
                </Link>
            </div>
        :
            <div className='logoSize'>
                <Link to={"/"} className='flex'>
                    <img src="/Logo/GV.png" alt="." />
                    <span className='center2 greyvalour greyvalourFont3'>
                        Grey Valour
                    </span>
                </Link>
            </div>
        }
    </div>
    
  )
}

export default Logo