import React from 'react'

const ListStages = ({values,currentSatge}) => {
    const myVal = values ? values.split(",") : null
  return (
    <div>
        {currentSatge && myVal && myVal.map((x,count)=>(
            x.trim().toLowerCase() === currentSatge.trim().toLowerCase() ? (
                <div className="pt_05 bold5 font_14" key={count}>
                    <span className="text-primary">
                        {x.trim()}
                    </span>
                </div>
            )
            :
            (
                <div className="pt_05 bold5 font_14" key={count}>
                    {x.trim()}
                </div>
            )
        ))
        }
        
    </div>
  )
}

export default ListStages